import React, { useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { BsFillEyeFill } from "react-icons/bs";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { COLORS, FIRESTORE } from "../../utils/constant";
import { setReports } from "../../redux/slices/userSlice";

const ReportBox = ({
  id,
  club,
  event,
  reason,
  reporter,
  eventCreator,
  isEventReported,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    userReducer: { reports },
  } = useSelector((state) => state);

  const _handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _handleDeleteReport();
      }
    });
  };

  const _handleDeleteReport = async () => {
    setLoading(true);
    await FIRESTORE.collection("reports").doc(id).delete();
    const filteredReports = reports?.data?.filter?.((o) => o?.id !== id);
    dispatch(setReports([...(filteredReports || [])]));
    setLoading(false);
  };

  return (
    <div className="flex justify-between flex-col bg-theme-black rounded-3xl w-full sm:w-96 mr-0 sm:mr-5 mt-4 cursor-pointer relative">
      {loading && (
        <div className="rounded-3xl absolute z-30 bg-white bg-opacity-25 w-full h-full flex items-center justify-center">
          <div
            role="status"
            style={{ width: "1.65rem", height: "1.65rem" }}
            className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="flex justify-between flex-col w-full h-full p-3 sm:p-4">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <img
              alt="Logo"
              src={isEventReported ? event?.photo : club?.photos?.[0]}
              className="w-28 h-20 rounded-2xl bg-gray-500"
            />
            <p className="text-white mb-0 pb-0 ml-3 text-xl font-semibold">
              {isEventReported ? "Event" : "Club"}
            </p>
          </div>
          <div className="flex space-x-3">
            <Link
              to={
                isEventReported
                  ? `/event/${event?.eventId}`
                  : `/club/${club?.id}`
              }
            >
              <div className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-white border-solid cursor-pointer">
                <BsFillEyeFill size={20} color="#ffffff" />
              </div>
            </Link>
            <div
              onClick={() => !loading && _handleDeleteConfirmation()}
              className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-red-500 border-solid cursor-pointer"
            >
              <RiDeleteBin5Fill size={20} color="rgb(239, 68, 68)" />
            </div>
          </div>
        </div>

        <p className="text-white mb-0 pb-0 mt-3 text-xl font-semibold">
          {isEventReported ? event?.title : club?.name}
        </p>
        <div className="flex mt-3">
          <p className="text-white">
            <span>Reason: </span>
            <span className="bg-white text-black font-semibold">{reason}</span>
          </p>
        </div>

        {reporter && (
          <>
            <div className="border border-white w-full mt-3"></div>
            <div className="mt-3">
              <p className="text-white mb-0 pb-0 text-xl font-semibold">
                {isEventReported ? "Event" : "Club"} Reporter
              </p>
              <p className="text-white mt-2">
                {reporter?.name || "Moertsch User"}
              </p>
              {reporter?.email !== "" &&
                reporter?.email !== null &&
                reporter?.email !== undefined && (
                  <div className="flex">
                    <span className="w-6">
                      <MdEmail size={20} color="#ffffff" />
                    </span>{" "}
                    <p className="text-white pl-2">{reporter?.email}</p>
                  </div>
                )}
              {reporter?.phone !== "" &&
                reporter?.phone !== null &&
                reporter?.phone !== undefined && (
                  <div className="flex">
                    <span className="w-6">
                      <FaPhoneAlt size={20} color="#ffffff" />
                    </span>{" "}
                    <p className="text-white pl-2">{reporter?.phone}</p>
                  </div>
                )}
            </div>
          </>
        )}

        {eventCreator && (
          <>
            <div className="border border-white w-full mt-3"></div>
            <div className="mt-3">
              <p className="text-white mb-0 pb-0 text-xl font-semibold">
                {isEventReported ? "Event" : "Club"} Creator
              </p>
              <p className="text-white mt-2">
                {eventCreator?.name || "Moertsch User"}
              </p>
              {eventCreator?.email !== "" &&
                eventCreator?.email !== null &&
                eventCreator?.email !== undefined && (
                  <div className="flex">
                    <span className="w-6">
                      <MdEmail size={20} color="#ffffff" />
                    </span>{" "}
                    <p className="text-white pl-2">{eventCreator?.email}</p>
                  </div>
                )}
              {eventCreator?.phone !== "" &&
                eventCreator?.phone !== null &&
                eventCreator?.phone !== undefined && (
                  <div className="flex">
                    <span className="w-6">
                      <FaPhoneAlt size={20} color="#ffffff" />
                    </span>{" "}
                    <p className="text-white pl-2">{eventCreator?.phone}</p>
                  </div>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const ViewReports = () => {
  const {
    userReducer: { reports },
  } = useSelector((state) => state);

  const DATA = reports?.data?.filter((o) => o?.event || o?.club) || [];
  const STATUS = reports?.status;

  return STATUS === "loading" ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex h-full flex-1 container py-4 justify-center items-center">
        <div
          role="status"
          style={{ color: COLORS?.PINK }}
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : STATUS === "success" && DATA?.length > 0 ? (
    <div className="pb-4 container">
      <div className="flex flex-wrap justify-center">
        {DATA.map((v, i) => {
          return <ReportBox key={i} {...(v || {})} />;
        })}
      </div>
    </div>
  ) : (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="container h-full py-4 flex flex-1 justify-center items-center">
        <p className="text-2xl text-red-600 text-center">No report found!</p>
      </div>
    </div>
  );
};

export default ViewReports;
