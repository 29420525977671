import { FIRESTORE } from "../../utils/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getConfig = createAsyncThunk(
  "app/getConfig",
  async (_, { rejectWithValue }) => {
    try {
      const request = await FIRESTORE.collection("config")
        .doc("config_data")
        .get();
      const data = await request?.data();
      return data || {};
    } catch (error) {
      console.log("error in getting my events ===> ", error?.message);
      rejectWithValue("");
    }
  }
);
