import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeBox = ({ name, link, icon, id }) => {
  const {
    userReducer: { users, events, clubs, reports, genres },
  } = useSelector((state) => state);

  let DATA = [];
  let STATUS = [];

  if (id === "users") {
    DATA = users?.data;
  } else if (id === "clubs") {
    DATA = clubs?.data;
  } else if (id === "events") {
    DATA = events?.data;
  } else if (id === "links") {
    DATA =
      events?.data?.filter?.(
        (o) =>
          o?.affiliateLink !== null &&
          o?.affiliateLink !== undefined &&
          o?.affiliateLink?.trim?.() !== ""
      ) || [];
  } else if (id === "reports") {
    DATA = reports?.data;
  } else if (id === "genres") {
    DATA = genres?.data;
  }

  if (id === "users") {
    STATUS = users?.status;
  } else if (id === "clubs") {
    STATUS = clubs?.status;
  } else if (id === "events" || id === "links") {
    STATUS = events?.status;
  } else if (id === "reports") {
    STATUS = reports?.status;
  } else if (id === "genres") {
    STATUS = genres?.status;
  }

  return (
    <div className="col-span-6 md:col-start-2 md:col-span-4 2xl:col-span-2">
      <Link to={link} className="no-underline">
        <div className="w-full px-4 py-3 rounded-3xl bg-white h-28 xs:h-36 flex items-start">
          <div className="h-full flex flex-1 flex-col items-center">
            <p className="text-lg xs:text-2xl capitalize font-medium text-black mb-0">
              {name}
            </p>
            <p className="text-2xl flex flex-1 items-center xs:text-4xl font-semibold text-red-600 mb-0">
              {STATUS === "loading" ? (
                <div
                  role="status"
                  className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : STATUS === "success" ? (
                DATA?.length || 0
              ) : (
                0
              )}
            </p>
          </div>
          {typeof icon === "object" ? (
            icon
          ) : (
            <img src={icon} alt="icon" className="w-10 xs:w-12" />
          )}
        </div>
      </Link>
    </div>
  );
};

export default HomeBox;
