import React, { useState } from "react";
import "./index.css";
import Swal from "sweetalert2";
import { FiPlus } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import DivContainer from "../../Components/DivContainer";
import { setTimetable } from "../../redux/slices/appSlice";
import TimetableScrollableTabs from "../../Components/TimetableScrollableTabs";

const Timetable = ({ startDate, endDate, onSavePress }) => {
  const dispatch = useDispatch();
  const [stageTxt, setStageTxt] = useState("");
  const [stageTxtError, setStageTxtError] = useState("");

  let timetable = useSelector((state) => state?.appReducer?.timetable);
  let stages = useSelector((state) => state?.appReducer?.timetable?.stages);

  const _removeStage = (stageId) => {
    let takeTimetableReplica = { ...(timetable || {}) };
    // need to remove all artist in which this stage name exists
    const addObject = {
      ...(takeTimetableReplica || {}),
      stages: [
        ...(takeTimetableReplica?.stages?.filter?.((o) => o?.id !== stageId) ||
          []),
      ],
    };

    dispatch(setTimetable(addObject));
  };

  const _addStage = () => {
    const TRIM_AND_LOWERCASE_STAGE = stageTxt?.trim?.()?.toLowerCase?.();
    if (!TRIM_AND_LOWERCASE_STAGE) {
      setStageTxtError("Stage name must be required");
      return;
    }
    const FIND_STAGE = stages?.find?.(
      (o) => o?.text?.trim?.()?.toLowerCase?.() === TRIM_AND_LOWERCASE_STAGE
    );
    if (FIND_STAGE) {
      setStageTxtError("This stage name is already exists");
      return;
    }

    let takeTimetableReplica = { ...(timetable || {}) };
    const addObject = {
      ...(takeTimetableReplica || {}),
      stages: [
        ...(takeTimetableReplica?.stages || []),
        {
          id: (stages?.length || 0) + 1,
          text: stageTxt?.trim?.(),
        },
      ],
    };

    dispatch(setTimetable(addObject));

    setStageTxtError("");
    setStageTxt("");
  };

  const _onClearPress = () => {
    Swal.fire({
      title: "Are you sure to clear the timetable?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(setTimetable({}));
      }
    });
  };

  return (
    <div className="bg-black">
      <div className="grid grid-cols-12 gap-2 w-full container items-center py-5">
        <DivContainer>
          <div className="bg-theme-black mt-3 flex rounded-3xl pr-5">
            <input
              type="text"
              name="capacity"
              value={stageTxt}
              onChange={({ target }) => setStageTxt(target?.value)}
              placeholder={`Add Stage No. ${(stages?.length || 0) + 1}`}
              className="text-white bg-theme-black py-3 px-3 rounded-3xl w-full capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
            />
            <button
              className="ml-3"
              onClick={_addStage}
              disabled={stageTxt?.trim?.()?.length < 1}
            >
              <FiPlus
                size={25}
                color={stageTxt?.trim?.()?.length < 1 ? "#9ca3af" : "#ffffff"}
              />
            </button>
          </div>
          {!!stageTxtError?.trim?.() && (
            <p className="mt-2 ml-2 mb-0 text-red-600 text-sm">
              {stageTxtError}
            </p>
          )}
        </DivContainer>
        {stages?.length > 0 && (
          <div className="col-start-1 col-end-13 sm:col-start-3 sm:col-end-11 lg:col-start-4 lg:col-end-10 flex flex-wrap w-full">
            {stages?.map?.((v, i) => (
              <div
                key={i}
                style={{ background: "#373535" }}
                className="flex items-center mr-3 pl-3 mt-3 py-2 rounded-xl"
              >
                <span className="text-white text-sm pr-3">
                  {`${i + 1}. ${v?.text}`}
                </span>
                <span
                  onClick={() => _removeStage(v?.id)}
                  className="cursor-pointer px-3 text-gray-50"
                >
                  x
                </span>
              </div>
            ))}
          </div>
        )}
        <DivContainer>
          <TimetableScrollableTabs
            stages={stages}
            endDate={endDate}
            startDate={startDate}
          />
        </DivContainer>
        {/* last buttons  */}
        <DivContainer>
          <div className="flex justify-evenly items-center">
            <button
              onClick={_onClearPress}
              className={`flex items-center justify-center bg-transparent w-36 sm:w-44 h-11 rounded-full cursor-pointer border border-white`}
            >
              <span className="text-white text-sm sm:text-base">Clear</span>
            </button>
            <button
              onClick={onSavePress}
              className={`flex items-center justify-center bg-gray-800 w-36 sm:w-44 h-11 rounded-full cursor-pointer`}
            >
              <span className="text-white text-sm sm:text-base">Save</span>
            </button>
          </div>
        </DivContainer>
      </div>
    </div>
  );
};

export default Timetable;
