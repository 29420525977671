import React from "react";
import { MAX_FILE_PICKER_LENGTH } from "../utils/constant";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import Swal from "sweetalert2";

const ImageAndVideoSelector = ({ 
  file, 
  setFiles, 
  handleRemoveFile,
  type = "image", 
  filePickerWithFiles,
  imagesAndVideos = [],
}) => {
  const IS_IMAGE = type === "photo";

  const fileToDataURL = (file) => {
  var reader = new FileReader()
  return new Promise(function (resolve, reject) {
    const FILE_TYPE = _findMediaType(file?.type);
    if (FILE_TYPE === "photo") {
      reader.onload = function (event) {
        resolve({
          file: event?.target?.result,
          rawFile: file,
          type: FILE_TYPE,
          service: "firebase",
        })
      }
      reader.readAsDataURL(file)
    } else {
      const videourl = URL.createObjectURL(file);
      resolve({
        file: videourl,
        rawFile: file,
        type: FILE_TYPE,
        service: "firebase",
      })
    }
  })
}  

  const _onFilePick = async (event) => {
    const targetFiles = event?.target?.files;
    if (!!targetFiles?.[0]) {
      const KEYS = Object.keys(targetFiles);
      const { diff } = _handleLimitOfFileSelection(KEYS?.length);
      const ARR = KEYS?.slice?.(0, (diff));
      const FILES = await Promise?.all?.(ARR?.map?.((_, idx) => fileToDataURL(targetFiles?.[idx])));
      setFiles([...(FILES || [])]);
    }
  };

  const _handleLimitOfFileSelection = (selectedLength) => {
    const ARR_LENGTH = imagesAndVideos?.length;
    const DIFF = MAX_FILE_PICKER_LENGTH - ARR_LENGTH;
    const HOW_MANY = selectedLength > DIFF ? DIFF : selectedLength
    if(selectedLength > DIFF){
      Swal.fire({
        title: "Done",
        icon: "info",
        text: `Maximum 10 files allowed to upload, only ${DIFF} ${DIFF > 1 ? 'files' : 'file'} from start will be selected!`,
      });
    }
    return {
      allow: HOW_MANY !== 0,
      diff: HOW_MANY
    }
  }

  const _findMediaType = (type) => {
    if (!type) return 'photo';
    if (type?.includes?.('image')) {
      return 'photo';
    }
    if (type?.includes?.('video')) {
      return 'video';
    }
  }

  const PLACE_HOLDER = "Select Image or Video Here";
  const ACCEPT = "image/png, image/jpg, image/jpeg, image/webp, video/mp4, video/ogg, video/webm";
  const CLOSE_ICON = <AiOutlineClose size={16} color="black" className="cursor-pointer"  />;

  return (
      !!file ? (
        <div className="relative pl-3 pt-3">
          <div className="absolute top-3 shadow-xl border-gray-400 border -right-2 rounded-full w-6 h-6 flex justify-center items-center bg-white cursor-pointer z-10" onClick={handleRemoveFile}>
            {CLOSE_ICON}
          </div>
          {IS_IMAGE ? (
            <img
              alt="event"
              src={file}
              className="w-24 h-24 rounded-lg"
            />
          ): (
            <video className="w-24 h-24 rounded-lg" autoPlay={false}>
              <source src={file} type="video/mp4" />
              don't support
            </video>
          )}
        </div>
      ) : (
        filePickerWithFiles ? (
          <div className="relative pl-3 pt-3">
            <label htmlFor="take-img" className="w-24 h-24 flex justify-center items-center rounded-lg border border-white border-dashed cursor-pointer">
              <AiOutlinePlus size={40} color={"white"} />
            </label>
            <input
              multiple
              type="file"
              id="take-img"
              accept={ACCEPT}
              className="hidden"
              onChange={_onFilePick}
             />
          </div>
        ) : (
          <div className="max-w-2xl rounded-lg shadow-xl bg-theme-black">
            <div className="flex items-center justify-center h-56 w-full">
              <label
                htmlFor="take-img"
                className="flex flex-col w-full h-full justify-center items-center cursor-pointer"
              >
                <div className="py-10 flex flex-col w-full h-full justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 h-10 text-white "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p className="pt-1 text-lg tracking-wider text-white ">
                    {PLACE_HOLDER}
                  </p>
                </div>
                <input
                  multiple
                  type="file"
                  id="take-img"
                  accept={ACCEPT}
                  className="hidden"
                  onChange={_onFilePick}
                />
              </label>
            </div>
          </div>
        )
      )
  );
};

export default ImageAndVideoSelector;
