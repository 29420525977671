import React, { useEffect, useState } from "react";
import user from "../../Images/user.png";
import Tumb from "../../Images/Tumb.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Club2 from "../../Images/Club2.png";
import event from "../../Images/event.png";
import LinkImg from "../../Images/link.png";
import { Offcanvas } from "react-bootstrap";
import { RiUploadFill } from "react-icons/ri";
import { CgLoadbarSound } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import Timetable from "../../Images/timetable.svg";
import { MdOutlineDashboard } from "react-icons/md";
import { googleLogout } from "@react-oauth/google";
import { setUser } from "../../redux/slices/userSlice";
import { AiOutlineClose, AiTwotoneFlag } from "react-icons/ai";
import { BsFillCloudUploadFill, BsPower } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";

const OPTIONS = [
  {
    icon: <MdOutlineDashboard color="#000" size={30} />,
    link: "/",
    name: "Main",
  },
  {
    icon: user,
    link: "/view-users",
    name: "View Users",
  },
  {
    icon: event,
    link: "/view-events",
    name: "View Events",
  },
  {
    icon: Club2,
    link: "/view-clubs",
    name: "View Clubs",
  },
  {
    icon: Tumb,
    link: "/validate-events",
    name: "Validate Events",
  },
  {
    icon: LinkImg,
    link: "/view-tickets",
    name: "Validate Tickets",
  },
  {
    icon: <AiTwotoneFlag color="#000" size={30} />,
    link: "/view-reports",
    name: "View Reports",
  },
  {
    name: "View Timetable Screenshots",
    link: "/view-timetable-screenshots",
    icon: Timetable,
  },
  {
    link: "/genres",
    name: "View Genres",
    icon: <CgLoadbarSound color="#000" size={30} />,
  },
  {
    link: "/upload-clubs",
    name: "Upload Clubs",
    icon: <BsFillCloudUploadFill color="#000" size={30} />,
  },
  {
    link: "/upload-events",
    name: "Upload Events",
    icon: <RiUploadFill color="#000" size={30} />,
  },
];

const Nav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();

  const {
    userReducer: { userData },
  } = useSelector((state) => state);

  useEffect(() => {
    setShow(false);
  }, [location]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLogout = () => {
    if (userData) {
      dispatch(setUser(null));
      googleLogout();
      navigation("/login", {
        replace: true,
      });
    }
  };

  return (
    <div style={{ minHeight: "10rem" }}>
      <div className="h-40 w-full flex items-center px-6 sm:px-10">
        <span onClick={handleShow} className="cursor-pointer w-8">
          <GiHamburgerMenu size={28} color="#fff" />
        </span>
        <div className="flex flex-1 flex-col px-5 items-center justify-center h-3">
          <div className="w-16 xs:w-20 h-16 xs:h-20 rounded-full">
            <img
              alt="user"
              className="w-full h-full rounded-full"
              src={
                userData?.photo
                  ? userData?.photo
                  : require("../../Images/profile_user.jpeg")
              }
            />
          </div>
          <p className="mt-2 text-white text-base xs:text-lg mb-0 text-center">
            Welcome {userData?.name?.split?.(" ")?.[0]}
          </p>
        </div>
        <span onClick={handleLogout} className="cursor-pointer w-8">
          <BsPower size={30} color="rgb(220, 38, 38)" />
        </span>
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Body className="bg-theme-black">
          <div
            onClick={handleClose}
            className="bg-transparent mt-12 ml-6 cursor-pointer"
          >
            <AiOutlineClose size={25} color="#fff" />
          </div>
          <div className="flex items-center flex-col mt-10">
            {OPTIONS.map((v, i) => (
              <Link key={i} to={v?.link} className="no-underline">
                <button className="flex items-center justify-between bg-white rounded-full pr-3 pl-5 mt-4 cursor-pointer h-14 w-72 text-black">
                  <span className="flex flex-1">{v?.name}</span>
                  {typeof v?.icon === "string" ? (
                    <img src={v?.icon} alt="icon" className="w-9" />
                  ) : (
                    v?.icon
                  )}
                </button>
              </Link>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Nav;
