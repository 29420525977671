import React, { useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { FiLink } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FIRESTORE } from "../utils/constant";
import { MdLocationOn } from "react-icons/md";
import { _mediaImg } from "../utils/functions";
import { VscChromeClose } from "react-icons/vsc";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { setTickets } from "../redux/slices/userSlice";
import { BsCheck2, BsFillCalendarEventFill } from "react-icons/bs";

const TicketBox = ({
  title,
  media,
  photo,
  status,
  verify,
  endDate,
  endTime,
  userId,
  onClick,
  ticket,
  eventId,
  location,
  startDate,
  startTime,
  ...rest
}) => {
  const dispatch = useDispatch();
  const {
    userReducer: { tickets },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);

  const _handleVerification = async (status) => {
    setLoading(true);
    const { ticketId, userId } = ticket;
    await FIRESTORE.collection("tickets").doc(ticketId).update({
      status,
    });
    await FIRESTORE.collection("notifications").add({
      title,
      userId,
      id: eventId,
      // needs to be updated
      ...(!!photo && {
        image: photo || [],
      }),
      type: "event",
      message:
        status === "rejected"
          ? `Your ticket for ${title} has been rejected`
          : `Your ticket for ${title} has been approved`,
    });

    const findTicketIndex = tickets?.data?.findIndex?.(
      (o) => o?.ticketId === ticketId
    );
    const findTicket = tickets?.data?.find?.((o) => o?.ticketId === ticketId);

    let takeAllTicketsReplica = [...(tickets?.data || [])];
    takeAllTicketsReplica[findTicketIndex] = {
      ...(findTicket || {}),
      status,
    };
    dispatch(setTickets([...(takeAllTicketsReplica || [])]));
    setLoading(false);
  };

  const _handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _handleDeleteTicket();
      }
    });
  };

  const _handleDeleteTicket = async () => {
    setLoading(true);
    const { ticketId, userId } = ticket;
    await FIRESTORE.collection("tickets").doc(ticketId).delete();
    // needs to be updated
    await FIRESTORE.collection("notifications").add({
      title,
      userId,
      ...(!!photo && {
        image: photo || [],
      }),
      message: `Your ticket for ${title} has been deleted by admin`,
    });

    const filteredTickets = tickets?.data?.filter?.(
      (o) => o?.ticketId !== ticketId
    );

    dispatch(setTickets([...(filteredTickets || [])]));
    setLoading(false);
  };

  const { status: ticketStatus } = ticket;
  return (
    <div
      onClick={onClick}
      className={`rounded-3xl w-full sm:w-96 mr-0 sm:mr-5 mt-4 relative ${
        ticketStatus === "approved"
          ? "bg-green-900"
          : ticketStatus === "rejected"
          ? "bg-red-900"
          : "bg-theme-black"
      }`}
    >
      {loading && (
        <div className="rounded-3xl absolute z-30 bg-white bg-opacity-25 w-full h-full flex items-center justify-center">
          <div
            role="status"
            style={{ width: "1.65rem", height: "1.65rem" }}
            className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="flex justify-start flex-col w-full h-full p-3 sm:p-4">
        <div className="flex justify-between items-start">
          <img
            alt="Logo"
            src={_mediaImg(media, photo)}
            className="w-28 h-20 rounded-2xl bg-gray-500"
          />
          <div>
            {!verify && (
              <>
                <div className="flex space-x-3">
                  {ticketStatus !== "approved" && (
                    <div
                      onClick={() =>
                        !loading && _handleVerification("approved")
                      }
                      className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-green-400 border-solid cursor-pointer"
                    >
                      <BsCheck2 size={20} color="rgba(52, 211, 153)" />
                    </div>
                  )}
                  {ticketStatus !== "rejected" && (
                    <div
                      onClick={() =>
                        !loading && _handleVerification("rejected")
                      }
                      className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-white border-solid cursor-pointer"
                    >
                      <VscChromeClose size={20} color="#ffffff" />
                    </div>
                  )}

                  <div
                    onClick={() => !loading && _handleDeleteConfirmation()}
                    className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-red-500 border-solid cursor-pointer"
                  >
                    <RiDeleteBin5Fill size={20} color="rgb(239, 68, 68)" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div>
          <p className="text-white mb-0 pb-0 mt-3 text-xl font-semibold">
            {title}
          </p>
          <div className="flex mt-2">
            <span className="w-6">
              <MdLocationOn size={20} color="#ffffff" />
            </span>{" "}
            <p className="text-white pl-2 text-sm">{location?.address}</p>
          </div>
          <div className="flex">
            <span className="w-6">
              <BsFillCalendarEventFill size={18} color="#ffffff" />{" "}
            </span>
            <p className="text-white pl-2 text-sm">
              {moment(startDate, "x").format("DD/MM/YYYY")}
              {`${
                endDate && endDate !== startDate
                  ? `-${moment(endDate, "x").format("DD/MM/YYYY")}`
                  : ""
              }`}
              {`${startTime ? `  -  ${startTime}` : ""}`}
              {`${endTime ? `-${endTime}` : ""}`}
            </p>
          </div>
          {!!ticket?.url && (
            <div className="flex">
              <span className="w-6">
                <FiLink size={20} color="#ffffff" />
              </span>{" "}
              <a
                target="_blank"
                href={ticket?.url}
                className="text-blue-400 pl-2 text-sm break-all"
              >
                Open Ticket
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketBox;
