import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import _ from "lodash";
import Swal from "sweetalert2";
import firebase from "firebase";
import { useSelector } from "react-redux";
import Genre from "../../Images/Genre.png";
import { BsChatDots } from "react-icons/bs";
import suffix from "../../Images/suffix.png";
import { AiOutlinePlus } from "react-icons/ai";
import GenrePopup from "../../Components/GenrePopup";
import DivContainer from "../../Components/DivContainer";
import { useNavigate, useParams } from "react-router-dom";
import FileSelector from "../../Components/FileSelector";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { MdLocationPin, MdKeyboardArrowRight } from "react-icons/md";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { setClubs } from "../../redux/slices/userSlice";
import { uploadFileToFirebase } from "../../utils/functions";
import { COLORS, FIRESTORE, MAP_API_KEY } from "../../utils/constant";

const ReportsDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationRef = useRef(null);
  const [img, setImg] = useState("");
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [imgFile, setImgFile] = useState(null);
  const [location, setLocation] = useState(null);
  const [clubData, setClubData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(params?.id);
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [selectedGenresError, setSelectedGenresError] = useState(false);

  const CLUB_ID = params?.id;

  const {
    userReducer: { genres, clubs, userData },
  } = useSelector((state) => state);

  const FIND_GENRE_IDS = _.filter(genres?.data || [], function (o) {
    return selectedGenres?.includes?.(o?.name);
  })?.map?.((v) => v?.id);

  useEffect(() => {
    if (CLUB_ID) {
      const findedClub = clubs?.data?.find?.((o) => o?.id === CLUB_ID);
      if (findedClub) {
        setClubData({ ...(findedClub || {}) });
        _setData(findedClub);
      }
      setLoading(false);
    }
  }, []);

  const _setData = (findedClub) => {
    const DATA = findedClub || clubData;
    if (CLUB_ID && DATA) {
      setImg(DATA?.photos?.[0]);
      setName(DATA?.name);
      setAddress(DATA?.address);
      setLink(DATA?.affiliateLink);
      setDescription(DATA?.description);
      setLocation({ ...(DATA?.location || {}) });
      setSelectedGenres([...(DATA?.genres || [])]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const _onApply = (paramGenres) => {
    setModalShow(false);

    const FIND_GENRE_NAMES = _.filter(genres?.data || [], function (o) {
      return paramGenres?.includes?.(o?.id);
    })?.map?.((v) => v?.name);

    setSelectedGenres([...(FIND_GENRE_NAMES || [])]);
  };

  const _deleteGenre = (genreName) => {
    if (genreName) {
      setSelectedGenres((prevState) => [
        ...(prevState?.filter((o) => o !== genreName) || []),
      ]);
    }
  };

  const _onAddressHandler = (data, ...arg) => {
    setAddress(data?.label);
    geocodeByPlaceId(data?.value?.place_id)
      .then((results) => {
        setLocation({
          address: data?.label,
          lat: results?.[0]?.geometry?.location?.lat?.(),
          lng: results?.[0]?.geometry?.location?.lng?.(),
        });
      })
      .catch((error) =>
        console.log(
          "error in fetching coordinates using geocodeByPlaceId ===> ",
          error
        )
      );
  };

  const _clear = () => {
    setImgFile(null);
    setNameError(false);
    setImageError(false);
    setLocationError(false);
    setSelectedGenresError(false);

    if (CLUB_ID) {
      _setData();
    } else {
      setImg("");
      setLink("");
      setName("");
      setAddress("");
      setDescription("");
      setSelectedGenres([]);
    }
  };

  const _handleUpdate = async () => {
    try {
      setSubmitLoading(true);
      let downloadUrl;
      if (imgFile) {
        downloadUrl = await uploadFileToFirebase(imgFile, "club_images");
      }
      const clubObj = {
        address,
        location,
        name: name?.trim?.(),
        genres: selectedGenres,
        affiliateLink: link || "",
        description: description?.trim?.() || "",
        photos: [
          downloadUrl && downloadUrl,
          ...(clubData?.photos || []),
        ]?.filter?.((o) => o),
        timeStamp: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
      };
      await FIRESTORE.collection("clubs").doc(CLUB_ID).update(clubObj);

      const updatedClubObj = {
        id: CLUB_ID,
        ...clubObj,
      };

      const findAllClubIndex = clubs?.data?.findIndex((o) => o?.id === CLUB_ID);

      let takeAllClubsReplica = [...(clubs?.data || [])];
      takeAllClubsReplica[findAllClubIndex] = {
        ...updatedClubObj,
      };

      dispatch(setClubs([...(takeAllClubsReplica || [])]));
      Swal.fire({
        title: "Done",
        icon: "success",
        text: `${name} club updated successfully!`,
      });
    } catch (error) {
      console.log("error ===> ", error?.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const _handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const downloadUrl = await uploadFileToFirebase(imgFile, "club_images");
      const clubObj = {
        address,
        location,
        name: name?.trim?.(),
        genres: selectedGenres,
        userId: userData?.userId,
        affiliateLink: link || "",
        description: description?.trim?.() || "",
        photos: [downloadUrl, ...(clubData?.photos || [])],
        createdAt: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
        timeStamp: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
      };
      const created = await FIRESTORE.collection("clubs").add(clubObj);

      const createdClubObj = {
        id: created?.id,
        ...clubObj,
      };

      dispatch(setClubs([createdClubObj, ...(clubs?.data || [])]));
      _clear();
      Swal.fire({
        title: "Done",
        icon: "success",
        text: `${name} club added successfully!`,
      });
    } catch (error) {
      console.log("error ===> ", error?.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  const _validate = () => {
    setNameError(true);
    setImageError(true);
    setLocationError(true);
    setSelectedGenresError(true);
    if (name?.trim?.() && (!CLUB_ID ? imgFile : true) && location) {
      if (CLUB_ID) {
        _handleUpdate();
      } else {
        _handleSubmit();
      }
    }
  };

  return (
    <>
      <div className="flex justify-between items-center pt-4 px-2 sm:px-5">
        <button onClick={() => navigate(-1)} className="w-12 cursor-pointer">
          <IoIosArrowBack size={35} color="#fff" />
        </button>
        {!loading && (
          <p className="text-center text-white text-2xl">
            {CLUB_ID ? "Edit Club" : "Create Club"}
          </p>
        )}
        <div className="w-12 h-1"></div>
      </div>
      {loading ? (
        <div className="flex flex-1 h-screen-custom-fit bg-theme-black justify-center py-4 px-10 items-center">
          <div
            role="status"
            style={{ color: COLORS?.PINK }}
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : !loading && CLUB_ID && !clubData ? (
        <div className="flex flex-1 h-screen-custom-fit bg-theme-black justify-center py-4 px-10 items-center">
          <p className="text-2xl text-red-600 text-center">
            Sorry, club couldn't find!
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-2 w-full bg-black items-center px-4 py-5">
            <DivContainer>
              <FileSelector
                file={img}
                setFile={setImg}
                setRawFile={setImgFile}
              />
              {imageError && (CLUB_ID ? !img : !imgFile) && (
                <p className="mt-3 mb-0 text-red-600 text-sm">
                  Club image must be required
                </p>
              )}
            </DivContainer>

            {/* name Start  */}
            <DivContainer>
              <div className="flex flex-row items-center">
                <label className="items-center text-white relative">
                  Club Name
                  <div className="absolute top-0 -right-3">
                    <span className="text-red-600 text-xl">*</span>
                  </div>
                </label>
              </div>
              <input
                name="name"
                type="text"
                value={name}
                onChange={({ target }) => setName(target?.value)}
                className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
              />
              {nameError && name === "" && (
                <p className="mt-3 mb-0 text-red-600 text-sm">
                  Club name must be required
                </p>
              )}
            </DivContainer>

            <DivContainer>
              <div className="flex flex-row items-center">
                <BsChatDots size={25} color="#fff" />
                <label className="items-center text-white relative ml-2">
                  Description
                </label>
              </div>
              <textarea
                type="text"
                name="description"
                value={description}
                style={{ resize: "none" }}
                onChange={({ target }) => setDescription(target?.value)}
                className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
              ></textarea>
            </DivContainer>

            <DivContainer>
              <div className="flex flex-row items-center mb-3">
                <MdLocationPin size={25} color="#fff" />
                <label className="items-center text-white relative ml-1">
                  Location
                  <div className="absolute top-0 -right-3">
                    <span className="text-red-600 text-xl">*</span>
                  </div>
                </label>
              </div>
              {/* geocodeByAddress need to get lan & long by using "geocodeByAddress" function */}
              <GooglePlacesAutocomplete
                apiKey={MAP_API_KEY}
                ref={locationRef}
                selectProps={{
                  value: address,
                  onChange: _onAddressHandler,
                  ...(CLUB_ID && {
                    defaultInputValue: address,
                  }),
                  // styles: {
                  //   input: (provided) => ({
                  //     ...provided,
                  //     // color: "white",
                  //     // borderRadius: "1.5rem",
                  //     // backgroundColor: "#151515",
                  //   }),
                  //   option: (provided) => ({
                  //     ...provided,
                  //     // color: "blue",
                  //   }),
                  //   singleValue: (provided) => ({
                  //     ...provided,
                  //   }),
                  // },
                }}
              />
              {locationError && address === "" && (
                <p className="mt-3 mb-0 text-red-600 text-sm">
                  Club location must be required
                </p>
              )}
            </DivContainer>

            {/* Genre  */}
            <DivContainer>
              <div
                onClick={() => setModalShow(true)}
                className="flex w-full bg-theme-black rounded-3xl my-2 cursor-pointer py-3 px-3 items-center justify-between"
              >
                <div className="flex items-center">
                  <img src={Genre} alt="genre" />
                  <label className="ml-3 text-white">Genre</label>
                </div>
                <MdKeyboardArrowRight size={25} color="#fff" />
              </div>

              <GenrePopup
                show={modalShow}
                onApply={_onApply}
                currentGenres={FIND_GENRE_IDS}
                onClose={() => setModalShow(false)}
              />
              {(!selectedGenres || selectedGenres?.length < 1) &&
                selectedGenresError && (
                  <p className="mt-3 mb-0 text-red-600 text-sm">
                    Choose atleast 1 genre for the club
                  </p>
                )}
            </DivContainer>

            {selectedGenres?.length > 0 && (
              <div className="col-start-1 col-end-13 xs:col-start-3 xs:col-end-11 sm:col-start-4 sm:col-end-10 lg:col-start-5 lg:col-end-9 flex flex-wrap">
                {selectedGenres?.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => _deleteGenre(v)}
                    style={{ background: "#373535" }}
                    className="flex items-center mr-3 pl-3 mt-3 py-2 rounded-xl"
                  >
                    <span className="text-white text-sm pr-3">{v}</span>
                    <span className="cursor-pointer px-3 text-gray-50">x</span>
                  </div>
                ))}
              </div>
            )}

            <DivContainer>
              <div className="flex flex-row items-center mt-3">
                <img src={suffix} alt="button" className="mr-3" />
                <label className="items-center text-white relative">
                  Link for button
                </label>
              </div>
              <input
                type="text"
                value={link}
                onChange={({ target }) => setLink(target?.value)}
                className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
              />
            </DivContainer>

            {/* last buttons  */}
            <DivContainer>
              <div className="flex justify-evenly items-center">
                <button
                  onClick={_clear}
                  disabled={submitLoading}
                  className="flex items-center justify-between w-36 sm:w-44 pr-5 rounded-full cursor-pointer border"
                >
                  <div className="w-9 h-9 sm:w-11 sm:h-11 rounded-full flex justify-center items-center">
                    <IoIosClose size={30} color="#fff" />
                  </div>
                  <span className="text-white text-center flex flex-1 justify-center items-center text-sm sm:text-base">
                    Clear
                  </span>
                </button>
                <button
                  type="submit"
                  onClick={_validate}
                  disabled={submitLoading}
                  style={{ background: COLORS?.PINK }}
                  className={`flex items-center ${
                    submitLoading ? "justify-center" : "justify-between pl-5"
                  }  w-36 sm:w-44 h-11 rounded-full cursor-pointer`}
                >
                  {submitLoading ? (
                    <div
                      role="status"
                      style={{ width: "1.65rem", height: "1.65rem" }}
                      className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <span className="text-white text-sm sm:text-base">
                        {CLUB_ID ? "Update Club" : "Create Club"}
                      </span>
                      <div className="w-9 h-9 sm:w-11 sm:h-11 rounded-full bg-white flex justify-center items-center">
                        <AiOutlinePlus size={20} color="#000" />
                      </div>
                    </>
                  )}
                </button>
              </div>
            </DivContainer>
          </div>
        </form>
      )}
    </>
  );
};

export default ReportsDetails;
