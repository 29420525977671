import React, { useState } from "react";
import "./index.css";
import {
  COLORS,
} from "../../utils/constant";
import Swal from "sweetalert2";
import firebase from "firebase";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../../redux/slices/userSlice";
import DivContainer from "../../Components/DivContainer";
import FileSelector from "../../Components/FileSelector";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { saveLatLngInArr, saveGenresIdsInArr, _saveEvent, determineDateFormat, determineTimeFormat } from "../../utils/functions";

const UploadEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [rawFile, setRawFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const events = useSelector((state) => state?.userReducer?.events);
  const userData = useSelector((state) => state?.userReducer?.userData);

  const handleSubmit = (e) => {
    e?.preventDefault?.();
  };

  const _clear = () => {
    setError('');
    setFile(null);
    setRawFile(null)
    setLoading(false)
    setFileError(false)
    setSubmitLoading(false)
  };

  const _handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const EVENTS = JSON.parse(file);
      if (!EVENTS || typeof EVENTS !== 'object' || !Array.isArray(EVENTS)) {
        setError("Data format is incorrect");
        return;
      }
      if (EVENTS?.length < 1) {
        setError("No events founds in file");
        return;
      }
      const SAVE_GENRES_ID = await saveGenresIdsInArr(EVENTS);
      const EVENTS_WITH_LATLNG = await saveLatLngInArr(SAVE_GENRES_ID);
      const EVENTS_ARRAY_FOR_ADD = EVENTS_WITH_LATLNG?.map?.((v) => {
        
        const SIZE = v?.size || v?.Size || "";
        const EMAIL = v?.email || v?.Email || "";
        const TWITTER = v?.twitter || v?.Twitter || "";
        const SPOTIFY = v?.spotify || v?.Spotify || "";
        const FACEBOOK = v?.facebook || v?.Facebook || "";
        const CAPACITY = v?.capacity || v?.Capacity || "";
        const AFFILIATE = v?.affiliate || v?.Affiliate || v?.affiliateLink	|| v?.AffiliateLink || v?.affiliate_link || v?.affiliatelink || "";
        const INSTAGRAM = v?.instagram || v?.Instagram || "";
        const YOUTUBE = v?.youtube || v?.Youtube || v?.YouTube || "";
        const TITLE = v?.name || v?.Name || v?.Title || v?.title || "";
        const HOMEPAGE = v?.home_page || v?.homePage || v?.Home_Page ||  v?.HomePage ||  v?.Website || v?.website || "";
        const IMG = v?.img || v?.Img || v?.Image || v?.image || v?.photo || v?.Photo || v?.imgs || v?.Imgs || v?.Images || v?.images || v?.photos || v?.Photos || "";
        const START_TIME = v?.startTime || v?.StartTime || v?.start_time || v?.Start_Time || v?.starttime || v?.timeStart || v?.TimeStart || v?.time_start || v?.Time_Start || v?.timestart || "";
        const END_TIME = v?.endTime || v?.EndTime || v?.end_time || v?.End_Time || v?.endtime || v?.timeEnd || v?.TimeEnd || v?.time_end || v?.Time_End || v?.timeend || "";
        const START_DATE = v?.startDate || v?.StartDate || v?.start_date || v?.Start_Date || v?.startdate || v?.dateStart || v?.DateStart || v?.date_start || v?.Date_Start || v?.datestart || "";
        const END_DATE = v?.endDate || v?.EndDate || v?.end_date || v?.End_Date || v?.enddate || v?.dateEnd || v?.DateEnd || v?.date_end || v?.Date_End || v?.dateend || "";
        const DESCRIPTION = v?.description || v?.Description || v?.bio	|| v?.Bio || "";

        const LOCATION = {
          lat: v?.lat || 0,
          lng: v?.lng || 0,
          address: v?.address || '',
        };

        return {
          location: LOCATION,
          type: 'public',
          title: TITLE?.trim?.()?.toLowerCase?.() || '',
          genres: v?.genres || [],
          userId: userData?.userId,
          affiliate: AFFILIATE,
          capacity: CAPACITY,
          size: SIZE,
          status: 'approved',
          endTime: !!END_TIME ? 
          determineTimeFormat(END_TIME) : "",
          startTime: !!START_TIME ? 
            determineTimeFormat(START_TIME) : "",
          startDate: determineDateFormat(START_DATE),
          endDate: !!END_DATE
            ? determineDateFormat(END_DATE)
            : determineDateFormat(START_DATE),
          description: DESCRIPTION?.trim?.() || "",
          website: HOMEPAGE,
          email: EMAIL,
          spotify: SPOTIFY,
          twitter: TWITTER,
          youtube: YOUTUBE,
          facebook: FACEBOOK,
          media: [IMG || ""],
          instagram: INSTAGRAM,
          createdAt: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
          timeStamp: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
        }
      });

      const UPLOADED_EVENTS = await Promise.all(
        EVENTS_ARRAY_FOR_ADD?.map?.((v) => 
          _saveEvent(v)
        )
      );

      dispatch(setEvents([...(UPLOADED_EVENTS || []), ...(events?.data || [])]));
      _clear();
      Swal.fire({
        title: "Done",
        icon: "success",
        text: `Events in bulk added successfully!`,
      });
    } catch (error) {
      setError("Data format is incorrect or something went wrong!");
    } finally {
      setSubmitLoading(false);
    }
  };

  const _validate = () => {
    if (!file) {
      setFileError(true);
      return;
    }
    _handleSubmit()
  };

  return (
    <>
      <div className="flex justify-between items-center pt-4">
        <button onClick={() => navigate(-1)} className="w-12 cursor-pointer">
          <IoIosArrowBack size={35} color="#fff" />
        </button>
        <p className="text-center text-white text-2xl">
          Upload Events
        </p>
        <div className="w-12 h-1"></div>
      </div>
      <form onSubmit={handleSubmit} className="bg-black">
        <div className="grid grid-cols-12 gap-2 w-full container items-center py-5">
          <DivContainer>
            <FileSelector
              file={file}
              type="file"
              setFile={setFile}
              setRawFile={setRawFile}
              fileName={rawFile?.name}
              accept="application/json"
              placeholder={"Select Event's JSON file here"}
            />
            {fileError && (!file) && (
              <p className="mt-3 mb-0 text-red-600 text-sm">
                Events JSON file must be required in order to upload events in bulk
              </p>
            )}
          </DivContainer>
          <DivContainer>
            <div className="h-40 sm:h-52 bg-black">
              {loading ? (
                <div className="h-full flex flex-col flex-1 justify-center items-center">
                  <div
                    role="status"
                    style={{ color: COLORS?.PINK }}
                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ): !!error ? (
                  <div className="h-full flex flex-1 justify-center items-center">
                    <p className="text-2xl text-red-600 text-center">
                      {error}
                    </p>
                  </div>
              ) : (
                <div className="h-full flex flex-1 justify-center items-center">
                </div>
              )}
            </div>
          </DivContainer>
          {/* last buttons  */}
          <DivContainer>
            <div className="flex mt-10 justify-evenly items-center">
              <button
                onClick={_clear}
                disabled={submitLoading}
                className="flex items-center justify-between w-36 sm:w-44 pr-5 rounded-full cursor-pointer border"
              >
                <div className="w-9 h-9 sm:w-11 sm:h-11 rounded-full flex justify-center items-center">
                  <IoIosClose size={30} color="#fff" />
                </div>
                <span className="text-white text-center flex flex-1 justify-center items-center text-sm sm:text-base">
                  Clear
                </span>
              </button>
              <button
                type="submit"
                onClick={_validate}
                disabled={submitLoading}
                style={{ background: COLORS?.PINK }}
                className={`flex items-center ${
                  submitLoading ? "justify-center" : "justify-between pl-5"
                }  w-36 sm:w-44 h-11 rounded-full cursor-pointer`}
              >
                {submitLoading ? (
                  <div
                    role="status"
                    style={{ width: "1.65rem", height: "1.65rem" }}
                    className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    <span className="text-white text-sm sm:text-base">
                      Upload Events
                    </span>
                    <div className="w-9 h-9 sm:w-11 sm:h-11 rounded-full bg-white flex justify-center items-center">
                      <AiOutlinePlus size={20} color="#000" />
                    </div>
                  </>
                )}
              </button>
            </div>
          </DivContainer>
        </div>
      </form>
    </>
  );
};

export default UploadEvents;
