import moment from "moment";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { COLORS, FIRESTORE } from "../../utils/constant";
import TimetableScreenshotBox from "../../Components/TimetableScreenshotBox";

const ViewTimetableScreenshots = () => {
  const [screenshotsData, setScreenshotsData] = useState([]);
  const [screenshotsStatus, setScreenshotsStatus] = useState("loading");
  let { status, data } = useSelector((state) => state?.userReducer?.users);

  useEffect(() => {
    _fetchTimetableScreenshots(data);
  }, [status, data]);

  const _fetchTimetableScreenshots = async (data) => {
    try {
      setScreenshotsStatus("loading");
      const events = [];

      const request = await FIRESTORE.collection("events").get();
      if (request?.size < 1) {
        setScreenshotsStatus("success");
        setScreenshotsData([]);
      }

      request?.forEach((result) => {
        const rsData = result?.data?.();
        if (rsData?.timetableScreenshots?.length > 0) {
          const checkPending = rsData?.timetableScreenshots?.some?.(
            (o) => o?.status === "pending"
          );
          if (!checkPending) {
            return;
          }

          const tsUserId = rsData?.timetableScreenshots?.[0]?.userId;
          const eventTimetableScreenshotsUploader = data?.find?.(
            (o) => o?.userId === tsUserId
          );

          events?.push({
            eventId: result?.id,
            ...(rsData || {}),
            eventTimetableScreenshotsUploader,
          });
        }
      });

      const sortEvents = events?.sort?.((a, b) => {
        var dateA = moment(a?.startDate, "x").format("x");
        var dateB = moment(b?.startDate, "x").format("x");
        return dateA < dateB ? -1 : 1;
      });

      setScreenshotsStatus("success");
      setScreenshotsData(sortEvents);
    } catch (error) {
      console.log("error ==> ", error);
      setScreenshotsStatus("failed");
    }
  };

  const isSuccess = status === "success" && screenshotsStatus === "success";
  const isLoading = status === "loading" || screenshotsStatus === "loading";

  return isLoading ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex h-full flex-1 container py-4 justify-center items-center">
        <div
          role="status"
          style={{ color: COLORS?.PINK }}
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : isSuccess && screenshotsData?.length > 0 ? (
    <div className="pb-4 container">
      <div className="flex flex-wrap justify-center">
        {screenshotsData.map((v, i) => {
          return (
            <TimetableScreenshotBox
              key={i}
              {...(v || {})}
              refresh={() => _fetchTimetableScreenshots(data)}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="container h-full py-4 flex flex-1 justify-center items-center">
        <p className="text-2xl text-red-600 text-center">
          No timetable screenshot found!
        </p>
      </div>
    </div>
  );
};

export default ViewTimetableScreenshots;
