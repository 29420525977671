import React, { useEffect } from "react";
import { store } from "./redux";
import {
  getClubs,
  getUsers,
  getEvents,
  getGenres,
  getReports,
  getTickets,
} from "./redux/actions/userAction";
import Home from "./Screens/Home";
import User from "./Screens/User/User";
import Club from "./Screens/Club/Club";
import Nav from "./Screens/NavBar/Nav";
import Tickets from "./Screens/Tickets";
import { useDispatch } from "react-redux";
import Error404 from "./Screens/Error404";
import Event from "./Screens/Events/Event";
import Signin from "./Screens/Login/Signin";
import Genres from "./Screens/Genres/Genres";
import UploadClubs from "./Screens/Upload/UploadClubs";
import ViewClub from "./Screens/Club/ViewClub/ViewClub";
import UploadEvents from "./Screens/Upload/UploadEvents";
import ViewReports from "./Screens/ViewReports/ViewReports";
import ViewEvent from "./Screens/Events/ViewEvent/ViewEvent";
import ReportsDetails from "./Screens/ViewReports/ReportsDetails";
import ValidateEvent from "./Screens/Events/ValidateEvent/ValidateEvent";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import ViewTimetableScreenshots from "./Screens/ViewTimetableScreenshots";

let letsFetch = true;

const AllRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const _fetchData = () => {
    dispatch(getClubs());
    dispatch(getUsers());
    dispatch(getEvents());
    dispatch(getGenres());
    dispatch(getReports());
    dispatch(getTickets());
  };

  useEffect(() => {
    const {
      userReducer: { userData },
    } = store.getState();
    if (userData) {
      if (letsFetch) {
        _fetchData();
        letsFetch = false;
      }
      if (location?.pathname === "/login" || location?.pathname === "login") {
        navigate(-1);
      }
    } else {
      if (location?.pathname !== "/login" && location?.pathname !== "login") {
        navigate("/login");
      }
      letsFetch = true;
    }
  }, [location]);

  return (
    <div className="w-full flex flex-1 flex-col h-screen">
      {[
        "/",
        "club",
        "event",
        "view-users",
        "view-clubs",
        "view-events",
        "view-tickets",
        "validate-events",
        "/genres",
        "/view-users",
        "/view-clubs",
        "/view-events",
        "/view-reports",
        "/view-tickets",
        "/validate-events",
        "/view-timetable-screenshots",
      ].includes(location?.pathname) && <Nav />}
      <Routes>
        <Route exact path="login" element={<Signin />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="event" element={<Event />}>
          <Route path=":eventId" element={<Event />} />
        </Route>
        <Route exact path="club" element={<Club />}>
          <Route path=":id" element={<Club />} />
        </Route>
        <Route exact path="view-reports" element={<ViewReports />}>
          <Route path=":id" element={<ReportsDetails />} />
        </Route>
        <Route exact path="genres" element={<Genres />} />
        <Route exact path="view-users" element={<User />} />
        <Route exact path="view-clubs" element={<ViewClub />} />
        <Route exact path="view-events" element={<ViewEvent />} />
        <Route exact path="validate-events" element={<ValidateEvent />} />
        <Route exact path="view-tickets" element={<Tickets />} />
        <Route
          exact
          path="view-timetable-screenshots"
          element={<ViewTimetableScreenshots />}
        />
        <Route exact path="upload-clubs" element={<UploadClubs />} />
        <Route exact path="upload-events" element={<UploadEvents />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
};

export default AllRoutes;
