import firebaseSDK from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
  projectId: "moertsch-app",
  measurementId: "G-CMNW5TH2R9",
  messagingSenderId: "553761028974",
  storageBucket: "moertsch-app.appspot.com",
  authDomain: "moertsch-app.firebaseapp.com",
  apiKey: "AIzaSyA4kHRi675VYmnW680N-rIPmvMo2CPiafM",
  appId: "1:553761028974:web:ea410b8bb0958d72a1df7f",

  // "apiKey":"AIzaSyCQ2wNyeXrIzifB0IKKVCalHXrDk7pg32w",
  // "authDomain":"live-stream-chat-efac5.firebaseapp.com",
  // "databaseURL":"https://live-stream-chat-efac5.firebaseio.com",
  // "projectId":"live-stream-chat-efac5",
  // "storageBucket":"live-stream-chat-efac5.appspot.com",
  // "messagingSenderId":"158909475587",
  // "appId":"1:158909475587:web:93ede0a9feef2b3f"
};

const firebase = firebaseSDK.initializeApp(firebaseConfig);
export const FIREBASE = firebase;
export const FIREBASE_SDK = firebaseSDK;
export const FIRESTORE = firebase.firestore();
export const FIRESTORE_AUTH = firebase.auth();
export const FIRESTORE_STORAGE = firebase.storage();

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EMAIL_VALIDATION = (email = "") => {
  const pattern = EMAIL_PATTERN;
  return String(email).toLowerCase().match(pattern);
};

export const PHONE_PATTERN = /\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){10}/;

export const PHONE_VALIDATION = (phone = "") => {
  const pattern = PHONE_PATTERN;
  return String(phone).toLowerCase().match(pattern);
};

export const GOOGLE_WEB_CLIENT_ID =
  "553761028974-fekopca50kb09n507l9aggk7817b21m2.apps.googleusercontent.com";

export const COLORS = {
  DEFAULT: "#DCDCDC",
  LABEL: "#FE2472",
  INFO: "#00BCD4",
  ERROR: "#DD0000",
  PINK: "#D70465",
  PINK_LIGHT: "#d14d8a",
  SUCCESS: "#4CAF50",
  WARNING: "#FF9800",
  MUTED: "#979797",
  BORDER_COLOR: "#FEFEFE",

  WHITE: "#FFFFFF",
  WHITE_SMOKE: "#E8E8E8",
  BLACK: "#000000",
  DARK: "#191919",
  CHIPS: "#2F2F30",
  GRAY: "#C5C5C5",
  TEXT_GRAY: "#ADB2B6",
  TEXT_DARK_GRAY: "#717171",
  TEXT_GRAY_RGBA: "rgba(255, 255, 255, 0.5)",
  LIGHT_GRAY: "#EEEEEE",
  DARK_GRAY: "#979797",
  MEDIUM_GRAY: "#414040",
  TEXT: "#858585",
  LIGHT_TEXT: "#828181",
  LIGHT_GRAY_TEXT: "#423F40",
  GOOGLE: "#EA4C89",
  LINK: "#1c7ffd",
  DISABLED: "#E3E3E3",
  DARK_BLACK: "#151515",
};

export const DEFAULT_ERROR_MESSAGE =
  "Sorry, An error has occurred. Please try again!";

export const MAP_API_KEY = "AIzaSyA4kHRi675VYmnW680N-rIPmvMo2CPiafM";

export const EVENT_APPROVAL_OPTIONS = [
  {
    id: "1",
    name: "Pending",
    value: "pending",
  },
  {
    id: "2",
    name: "Updating",
    value: "updating",
  },
  {
    id: "3",
    name: "Approved",
    value: "approved",
  },
  {
    id: "4",
    name: "Rejected",
    value: "rejected",
  },
];

export const FIREBASE_FUNCTION_URL =
  "https://us-central1-moertsch-app.cloudfunctions.net";

export const SIZE_OPTIONS = [
  {
    id: "1",
    name: "Small",
    value: "Small",
    capacity: "0-5000",
  },
  {
    id: "2",
    name: "Medium",
    value: "Medium",
    capacity: "5000-15000",
  },
  {
    id: "3",
    name: "Big",
    value: "Big",
    capacity: "15000-30000",
  },
  {
    id: "4",
    name: "Huge",
    value: "Huge",
    capacity: "30000-80000",
  },
  {
    id: "5",
    name: "Mega",
    value: "Mega",
    capacity: "80000+",
  },
];

export const MAX_FILE_PICKER_LENGTH = 10;

export const DEFAULT_IMAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/moertsch-app.appspot.com/o/moertsch-black-bg.jpg?alt=media&token=66dfc807-b3c5-4e96-849b-e9583f24594f&_gl=1*hgdm0g*_ga*NDk2NzU3MjEuMTY4Njg3NzIwMA..*_ga_CW55HF8NVT*MTY5ODU0MjUxNy4xNS4xLjE2OTg1NDMxOTIuNTIuMC4w";
