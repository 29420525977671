import React, { useState } from "react";
import Swal from "sweetalert2";
import { MdEmail } from "react-icons/md";
import { BsCheck2 } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { FIRESTORE } from "../utils/constant";
import "yet-another-react-lightbox/styles.css";
import { VscChromeClose } from "react-icons/vsc";
import Lightbox from "yet-another-react-lightbox";
import { _mediaImg, capitalizeWords } from "../utils/functions";

const TimetableScreenshotBox = ({
  photo,
  media,
  title,
  eventId,
  refresh,
  timetableScreenshots,
  eventTimetableScreenshotsUploader,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const _handleRefresh = () => {
    if (refresh && typeof refresh === "function") {
      refresh();
    }
  };

  const _handleRejectConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _handleRejectTimetableScreenshots();
      }
    });
  };

  const _handleRejectTimetableScreenshots = async () => {
    try {
      setLoading(true);
      await FIRESTORE.collection("events").doc(eventId).update({
        timetableScreenshots: [],
      });
      _handleRefresh();
      Swal.fire({
        title: "Rejected!",
        icon: "info",
        text: `Timetable screenshots of event ${capitalizeWords(
          title
        )} has been rejected!`,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error?.message || `Something went wrong!`,
      });
    } finally {
      setLoading(false);
    }
  };

  const _handleAcceptTimetableScreenshots = async () => {
    try {
      setLoading(true);
      const TIMETABLE_SCREENSHOTS = timetableScreenshots?.map?.((o) => ({
        ...(o || {}),
        status: "approved",
      }));
      await FIRESTORE.collection("events").doc(eventId).update({
        timetableScreenshots: TIMETABLE_SCREENSHOTS,
      });
      _handleRefresh();
      Swal.fire({
        title: "Done!",
        icon: "success",
        text: `Timetable screenshots of event ${capitalizeWords(
          title
        )} has been approved!`,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error?.message || `Something went wrong!`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-between flex-col bg-theme-black rounded-3xl w-full sm:w-96 mr-0 sm:mr-5 mt-4 relative">
      {loading && (
        <div className="rounded-3xl absolute z-30 bg-white bg-opacity-25 w-full h-full flex items-center justify-center">
          <div
            role="status"
            style={{ width: "1.65rem", height: "1.65rem" }}
            className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="flex justify-between flex-col w-full h-full p-3 sm:p-4">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <img
              alt="Logo"
              src={_mediaImg(media, photo)}
              className="w-28 h-20 rounded-2xl bg-gray-500"
            />
          </div>
          <div className="flex space-x-3">
            <div
              title="Accept"
              onClick={() => !loading && _handleAcceptTimetableScreenshots()}
              className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-green-400 border-solid cursor-pointer"
            >
              <BsCheck2 size={20} color="rgba(52, 211, 153)" />
            </div>
            <div
              title="Reject"
              onClick={() => !loading && _handleRejectConfirmation()}
              className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-red-500 border-solid cursor-pointer"
            >
              <VscChromeClose size={20} color="rgb(239, 68, 68)" />
            </div>
          </div>
        </div>
        <p className="text-white mb-0 pb-0 mt-3 text-xl font-semibold">
          {capitalizeWords(title)}
        </p>

        {eventTimetableScreenshotsUploader && (
          <>
            <div className="border border-white w-full mt-3"></div>
            <div className="mt-3">
              <p className="text-white mb-0 pb-0 text-xl font-semibold">
                Screenshots uploader
              </p>
              <p className="text-white mt-2">
                {eventTimetableScreenshotsUploader?.name || "Moertsch User"}
              </p>
              {!!eventTimetableScreenshotsUploader?.email && (
                <div className="flex">
                  <span className="w-6">
                    <MdEmail size={20} color="#ffffff" />
                  </span>{" "}
                  <p className="text-white pl-2">
                    {eventTimetableScreenshotsUploader?.email}
                  </p>
                </div>
              )}
              {!!eventTimetableScreenshotsUploader?.phone && (
                <div className="flex">
                  <span className="w-6">
                    <FaPhoneAlt size={20} color="#ffffff" />
                  </span>{" "}
                  <p className="text-white pl-2">
                    {eventTimetableScreenshotsUploader?.phone}
                  </p>
                </div>
              )}
            </div>
          </>
        )}
        <div className="border border-white w-full mt-1 mb-1"></div>
        <div className="flex flex-wrap items-center">
          {timetableScreenshots?.map?.((v, i) => (
            <div
              key={i}
              onClick={() => {
                setPhotoIndex(i);
                setOpen(true);
              }}
            >
              <img
                alt="Logo"
                src={v?.link}
                className="w-20 mt-2 mr-2 h-20 border border-gray-100 rounded-2xl bg-gray-500 cursor-pointer"
              />
            </div>
          ))}
        </div>
        <Lightbox
          open={open}
          index={photoIndex}
          close={() => {
            setOpen(false);
            setPhotoIndex(0);
          }}
          slides={timetableScreenshots?.map?.((v) => ({ src: v?.link }))}
        />
      </div>
    </div>
  );
};

export default TimetableScreenshotBox;
