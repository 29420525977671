import moment from "moment";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import TicketBox from "../../Components/TicketBox";
import { COLORS, FIREBASE_SDK, FIRESTORE } from "../../utils/constant";

const Tickets = () => {
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketStatus, setTicketStatus] = useState("loading");
  let { status, data } = useSelector((state) => state?.userReducer?.tickets);

  useEffect(() => {
    _fetchTicketEvents(data);
  }, [status, data]);

  const _fetchTicketEvents = async (data) => {
    try {
      setTicketStatus("loading");
      if (!data || data?.length < 1) {
        setTicketsData([]);
        setTicketStatus("success");
        return;
      }
      const eventIds = data?.map?.((v) => v?.eventId);
      const events = [];
      const tickets = [];

      const documentId = FIREBASE_SDK.firestore.FieldPath.documentId();
      const request = await FIRESTORE.collection("events")
        .where(documentId, "in", eventIds)
        .get();

      request?.forEach((result) =>
        events?.push({
          eventId: result.id,
          ...(result.data() || {}),
        })
      );

      data?.map?.((v) => {
        const foundEvent = events?.find((o) => o?.eventId === v?.eventId);
        tickets.push({
          ...(foundEvent || {}),
          ticket: v,
        });
      });

      const sortTickets = tickets?.sort?.((a, b) => {
        var dateA = moment(a?.startDate, "x").format("x");
        var dateB = moment(b?.startDate, "x").format("x");
        return dateA < dateB ? -1 : 1;
      });

      setTicketStatus("success");
      setTicketsData(sortTickets);
    } catch (error) {
      console.log("error ==> ", error);
      setTicketStatus("failed");
    }
  };

  const isSuccess = status === "success" && ticketStatus === "success";
  const isLoading = status === "loading" || ticketStatus === "loading";

  return isLoading ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex h-full flex-1 container py-4 justify-center items-center">
        <div
          role="status"
          style={{ color: COLORS?.PINK }}
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : isSuccess && ticketsData?.length > 0 ? (
    <div className="pb-4 container">
      <div className="flex flex-wrap justify-center">
        {ticketsData.map((v, i) => {
          return <TicketBox key={i} {...(v || {})} />;
        })}
      </div>
    </div>
  ) : (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="container h-full py-4 flex flex-1 justify-center items-center">
        <p className="text-2xl text-red-600 text-center">No ticket found!</p>
      </div>
    </div>
  );
};

export default Tickets;
