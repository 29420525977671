import React, { useEffect } from "react";
import UserImg from "../../Images/user.png";
import LinkImg from "../../Images/link.png";
import ClubImg from "../../Images/Club2.png";
import EventImg from "../../Images/event.png";
import HomeBox from "../../Components/HomeBox";
import { AiTwotoneFlag } from "react-icons/ai";
import { CgLoadbarSound } from "react-icons/cg";
// import { fixDatesInEventDataBase } from "../../utils/functions";

export const DATA = [
  {
    id: "users",
    link: "view-users",
    icon: UserImg,
    name: "Current Users",
  },
  {
    id: "clubs",
    link: "view-clubs",
    icon: ClubImg,
    name: "Current Clubs",
  },
  {
    id: "events",
    link: "view-events",
    icon: EventImg,
    name: "Current Events",
  },
  {
    id: "links",
    icon: LinkImg,
    link: "view-events",
    name: "Active Links",
  },
  {
    id: "reports",
    icon: <AiTwotoneFlag color="#000" size={30} />,
    link: "view-reports",
    name: "Reported Events/Clubs",
  },
  {
    id: "genres",
    icon: <CgLoadbarSound color="#000" size={30} />,
    link: "genres",
    name: "All Genres",
  },
];

const Home = () => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     fixDatesInEventDataBase();
  //   }, 2000);
  // }, []);

  return (
    <div className="h-screen-custom-fit py-4 bg-theme-black">
      <div className=" container grid grid-cols-6 gap-10 items-center justify-center h-full">
        {DATA.map((v, i) => (
          <HomeBox key={i} {...v} />
        ))}
      </div>
    </div>
  );
};

export default Home;
