import React, { forwardRef, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { COLORS } from "../../../utils/constant";
import "react-datepicker/dist/react-datepicker.css";
import EventBox from "../../../Components/EventBox";
import { filterBetweenTheStartAndEndDate } from "../../../utils/functions";

const BtnCustomInput = forwardRef(({ value, onClick, activeFilter, item, onActivePress }, ref) => (
  <button 
    ref={ref}
    onClick={() => {
      if(onClick && typeof onClick === 'function'){
        onClick(value)
      }
      onActivePress(value)
    }}
    style={{ background: activeFilter?.id === item?.id ? COLORS?.PINK : COLORS?.PINK_LIGHT }}
    className={`items-center justify-center w-36 xs:w-44 sm:w-48 px-2 h-14 rounded-full cursor-pointer mr-2 mt-2`}
  >
    <p className={`text-white text-center font-semibold text-xs xs:text-sm sm:text-base ${!!value ? '-mb-1' : 'mb-0'}`}>
      {item?.text}
    </p>
    <span className="text-white text-center text-xs sm:text-sm">
      {value}
    </span>
  </button>
));

const Btn = ({ item, activeFilter, onClick }) => (
  <button 
    onClick={onClick}
    style={{ background: activeFilter?.id === item?.id ? COLORS?.PINK : COLORS?.PINK_LIGHT }}
    className={`items-center justify-center w-36 xs:w-44 sm:w-48 px-2 h-14 rounded-full cursor-pointer mr-2 mt-2`}
  >
    <p className="text-white text-center font-semibold text-xs xs:text-sm sm:text-base -mb-1">
      {item?.text}
    </p>
    <span className="text-white text-center text-xs sm:text-sm">
      {item?.subText}
    </span>
  </button>
)

const ViewEvent = () => {
  const {
    userReducer: { events },
  } = useSelector((state) => state);
  const [endDate, setEndDate] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [startDate, setStartDate] = useState("");
  
  const DATE_OPTIONS = [
    {
      id: 'current-month',
      text: 'Current Month',
      subText: moment().format('MMMM, YYYY'),
      onDateFilter: (params) => _handleMonthlyFilter(params)
    },
    {
      id: 'current-year',
      text: 'Current Year',
      subText: moment().format('YYYY'),
      onDateFilter: (params) => _handleYearlyFilter(params)
    },
    {
      forDate: true,
      id: 'start-date',
      text: 'Start Date',
      state: startDate,
      subText: !startDate ? '-----' : startDate,
      onClearPress: () => setStartDate(''),
      onDateFilter: (type, date) => _handleStartDate(type, date)
    },
    {
      forDate: true,
      id: 'end-date',
      text: 'End Date',
      state: endDate,
      subText: !endDate ? '-----' : endDate,
      onClearPress: () => setEndDate(''),
      onDateFilter: (type, date) => _handleEndDate(type, date)
    },
  ];

  const [activeFilter, setActiveFilter] = useState(DATE_OPTIONS[0]);

  const _handleMonthlyFilter = (type) => {
    setActiveFilter(type);
  };
  
  const _handleYearlyFilter = (type) => {
    setActiveFilter(type);
  };
  
  const _handleStartDate = (type, date) => {
    if (!!endDate) {
      const selectedStartDate = moment(date, 'DD/MM/YYYY');
      const selectedEndDate = moment(endDate, 'DD/MM/YYYY');
      const checkDate = selectedStartDate?.isAfter(selectedEndDate);
      if(checkDate){
        setEndDate('');
      }
    }
    setStartDate(date);
    setActiveFilter(type);
  };

  const _handleEndDate = (type, date) => {
    if (!!startDate) {
      const selectedStartDate = moment(date, 'DD/MM/YYYY');
      const selectedEndDate = moment(startDate, 'DD/MM/YYYY');
      const checkDate = selectedStartDate?.isBefore(selectedEndDate);
      if(checkDate){
        Swal.fire({
          icon: "error",
          title: "Wrong!",
          text: "End date must be same or greater than starting date",
        });
        return;
      }
    }
    setEndDate(date);
    setActiveFilter(type);
  };

  const _filterData = (data = [], startDate, endDate) => {
    return (data?.filter?.((o) => filterBetweenTheStartAndEndDate(o?.startDate, Number(startDate), Number(endDate))) || []);
  }

  const _filterDataByDateFilter = (data = []) => {
    if(data?.length < 1){
      return [];
    }
    let RESPONSE = []
    if(activeFilter?.id === 'current-month'){
      const START_OF_MONTH = moment().startOf('month').startOf('day').startOf('hour').startOf('minute').startOf('second').format('x');
      const END_OF_MONTH = moment().endOf('month').endOf('day').endOf('hour').endOf('minute').endOf('second').format('x');
      RESPONSE = _filterData(data, START_OF_MONTH, END_OF_MONTH)
    }
    if(activeFilter?.id === 'current-year'){
      const START_OF_YEAR = moment().startOf('year').startOf('month').startOf('day').startOf('hour').startOf('minute').startOf('second').format('x');
      const END_OF_YEAR = moment().endOf('year').endOf('month').endOf('day').endOf('hour').endOf('minute').endOf('second').format('x');
      RESPONSE = _filterData(data, START_OF_YEAR, END_OF_YEAR)
    }
    if(activeFilter?.id === 'start-date' || activeFilter?.id === 'end-date'){
      const START_DATE = !!startDate ? (
        Number(moment(startDate).startOf('hour').startOf('minute').startOf('second').format('x'))
      ) : '';
      const END_DATE = !!endDate ? (
        Number(moment(endDate).endOf('hour').endOf('minute').endOf('second').format('x'))
      ) : '';
      RESPONSE = _filterData(data, START_DATE, END_DATE)
    }
    if(RESPONSE?.length < 1){
      return [];
    }
    const SORT_EVENTS = RESPONSE?.sort?.((a, b) => {
      var dateA = Number(a?.startDate);
      var dateB = Number(b?.startDate);
      // For Ascending order
      return dateA - dateB;
      // For Decending order
      // return dateB - dateA;
    });
    return SORT_EVENTS;
  }

  const SEARCHED_DATA =
    searchTxt !== ""
      ? events?.data?.filter?.((o) =>
          o?.title?.toLowerCase()?.includes?.(searchTxt?.toLowerCase?.())
        ) || []
      : events?.data || [];
  
  const DATA = _filterDataByDateFilter(SEARCHED_DATA);

  const STATUS = events?.status;

  return STATUS === "loading" ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex h-full flex-1 container py-4 justify-center items-center">
        <div
          role="status"
          style={{ color: COLORS?.PINK }}
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : STATUS === "success" ? (
    <div className="pb-4 container">
      <input
        name="search"
        type="text"
        value={searchTxt}
        placeholder={"Search event here..."}
        onChange={({ target }) => setSearchTxt(target?.value)}
        className="mb-3 w-full sm:w-full text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
      />
      <div className="my-3 flex flex-row items-center justify-center flex-wrap">
        {DATE_OPTIONS?.map?.((v, i) => (
          v?.forDate ? (
            <div key={i} className="relative">
              {!!v?.state && (
                <button className="absolute cursor-pointer z-10 shadow-lg w-6 h-6 top-1 right-1.5 flex justify-center items-center bg-white rounded-full" onClick={v?.onClearPress}>
                  <RxCross1  
                    size={15}
                    color="#000"
                  />
                </button>
              )}
              <DatePicker
                key={i}
                selected={v?.state}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => v?.onDateFilter(v, date)}
                customInput={
                  <BtnCustomInput 
                    item={v} 
                    activeFilter={activeFilter} 
                    onActivePress={(date) => {
                      if(!!date && !!v?.state && (date === moment(v?.state).format('DD/MM/YYYY'))){
                        setActiveFilter(v);
                      }
                    }} 
                  />
                }
              />
            </div>
          ) : (
             <Btn 
              key={i} 
              item={v} 
              activeFilter={activeFilter}
              onClick={() => {
                v?.onDateFilter(v);
              }} 
            />
          )
        ))}
      </div>
      <p className="text-xl sm:text-3xl mt-4 text-white text-center font-bold">
        {!!searchTxt ? `${DATA?.length} Search ${DATA?.length > 1 ? 'Events Found' : 'Event Found'}` :`${DATA?.length} ${DATA?.length > 1 ? 'Events' : 'Event'}`}
      </p>
      <div className="fixed z-10 bottom-10 right-10">
        <Link to="/event">
          <FaPlus
            size={40}
            color="#000"
            className="rounded-full p-1"
            style={{ background: COLORS?.PINK }}
          />
        </Link>
      </div>
      {DATA?.length < 1 && (
        <div className="py-4 h-96 flex flex-1 justify-center items-center">
          <p className="text-xl text-red-600 text-center">
            {!!searchTxt ? `No searched event found for "${searchTxt}"`: 'No event found!'}
          </p>
        </div>
      )}
      {DATA?.length > 0 && (
        <div className="flex flex-wrap justify-center">
          {DATA.map((v, i) => {
            return <EventBox key={i} {...(v || {})} allowValidation />;
          })}
        </div>
      )}
    </div>
  ): <></>;
};

export default ViewEvent;
