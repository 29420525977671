import React from "react";
import ImageAndVideoSelector from './ImageAndVideoSelector';

const MultipleFileSelector = ({
  loading,
  setImagesAndVideos,
  onRemoveMediaClick,
  imagesAndVideos = [],
  filePickerWithFiles
}) => {
  const _handleSaveImagesAndVideo = (files) => {
    setImagesAndVideos((prevState) => [...(prevState || []), ...(files || [])]);
  };

  const _handleRemoveFile = (index) => {
    imagesAndVideos?.splice?.(index, 1);
    setImagesAndVideos([...imagesAndVideos]);
  }

  return (
    <>
      {imagesAndVideos?.length > 0 ? (
        <div className="max-w-2xl flex flex-row flex-wrap pr-3 pb-3 rounded-lg shadow-xl bg-theme-black">
          {imagesAndVideos?.map?.((v, i) => (
            <ImageAndVideoSelector 
              key={i}
              index={i}
              type={v?.type}
              file={v?.file || v?.link}
              handleRemoveFile={() => _handleRemoveFile(i)}
            />
          ))}
          {imagesAndVideos?.length < 10 && (
            <ImageAndVideoSelector
              imagesAndVideos={imagesAndVideos}
              filePickerWithFiles
              setFiles={(files) =>
                _handleSaveImagesAndVideo(files)
              }
            />
          )}
        </div>
      ) : (
        <ImageAndVideoSelector
          imagesAndVideos={imagesAndVideos}
          setFiles={(files) =>
            _handleSaveImagesAndVideo(files)
          }
        />
      )}
    </>
  );
};

export default MultipleFileSelector;
