import { FIRESTORE } from "../../utils/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getEvents = createAsyncThunk(
  "user/getEvents",
  async (_, { rejectWithValue }) => {
    try {
      const request = await FIRESTORE.collection("events")
        // .where("type", "==", "public")
        .orderBy("timeStamp", "desc")
        .get();
      const data = [];
      request.forEach((res) => {
        data.push({
          eventId: res.id,
          ...(res.data() || {}),
        });
      });
      return data || [];
    } catch (error) {
      console.log("error in getting events ===> ", error?.message);
      rejectWithValue("");
    }
  }
);

export const getTickets = createAsyncThunk(
  "user/getTickets",
  async (_, { rejectWithValue }) => {
    try {
      const request = await FIRESTORE.collection("tickets").get();
      const data = [];
      if (request?.size < 1) {
        return data;
      }

      request.forEach(async (res) => {
        if (res?.exists) {
          const DATA = res.data();
          data.push({
            ticketId: res.id,
            ...(DATA || {}),
          });
        }
      });
      return data || [];
    } catch (error) {
      console.log("error in getting tickets ===> ", error?.message);
      rejectWithValue("");
    }
  }
);

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (_, { rejectWithValue }) => {
    try {
      const request = await FIRESTORE.collection("Users")
        .where("type", "!=", "admin")
        .get();
      const data = [];
      request.forEach((res) => {
        data.push({
          docId: res.id,
          ...(res.data() || {}),
        });
      });
      return data || [];
    } catch (error) {
      console.log("error in getting users ===> ", error?.message);
      rejectWithValue("");
    }
  }
);

export const getGenres = createAsyncThunk(
  "user/getGenres",
  async (_, { rejectWithValue }) => {
    try {
      const request = await FIRESTORE.collection("Genres")
        .orderBy("name", "asc")
        .get();
      const data = [];
      request.forEach((res) => {
        data.push({
          id: res.id,
          ...(res.data() || {}),
        });
      });
      return data || [];
    } catch (error) {
      console.log("error in getting genres ===> ", error?.message);
      rejectWithValue("");
    }
  }
);

export const getReports = createAsyncThunk(
  "user/getReports",
  async (_, { rejectWithValue }) => {
    try {
      const request = await FIRESTORE.collection("reports")
        .orderBy("createdAt", "desc")
        .get();

      if (request?.size < 1) {
        return [];
      }

      const data = [];

      request.forEach((res) => {
        if (res.exists) {
          data.push({
            id: res.id,
            ...(res.data() || {}),
          });
        }
      });

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let club = null;
        let event = null;
        let reporter = null;
        let eventCreator = null;

        if (element?.isEventReported) {
          const eventRequest = await FIRESTORE.collection("events")
            .doc(element?.eventId)
            .get();
          if (eventRequest?.exists) {
            event = {
              eventId: eventRequest?.id,
              ...(eventRequest?.data() || {}),
            };
          }

          if (element?.eventUserId) {
            const eventCreatorRequest = await FIRESTORE.collection("Users")
              .doc(element?.eventUserId)
              .get();
            if (eventCreatorRequest?.exists) {
              eventCreator = {
                userId: eventCreatorRequest?.id,
                ...(eventCreatorRequest?.data() || {}),
              };
            }
          }
        } else {
          const clubRequest = await FIRESTORE.collection("clubs")
            .doc(element?.clubId)
            .get();
          if (clubRequest?.exists) {
            club = {
              id: clubRequest?.id,
              ...(clubRequest?.data() || {}),
            };
          }
        }

        if (element?.userId) {
          const reporterRequest = await FIRESTORE.collection("Users")
            .doc(element?.userId)
            .get();
          if (reporterRequest?.exists) {
            reporter = {
              userId: reporterRequest?.id,
              ...(reporterRequest?.data() || {}),
            };
          }
        }
        data[index] = {
          ...(element || {}),
          club,
          event,
          reporter,
          eventCreator,
        };
      }

      return data || [];
    } catch (error) {
      console.log("error in getting reports ===> ", error?.message);
      rejectWithValue("");
    }
  }
);

export const getClubs = createAsyncThunk(
  "user/getClubs",
  async (_, { rejectWithValue }) => {
    try {
      const request = await FIRESTORE.collection("clubs")
        .orderBy("timeStamp", "desc")
        .get();

      if (request?.size < 1) {
        return [];
      }

      const data = [];

      request.forEach((res) => {
        if (res.exists) {
          data.push({
            id: res.id,
            ...(res.data() || {}),
          });
        }
      });

      return data || [];
    } catch (error) {
      console.log("error in getting clubs ===> ", error?.message);
      rejectWithValue("");
    }
  }
);
