import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Genre from "../Images/Genre.png";
import { BsCheck2 } from "react-icons/bs";
import { useSelector } from "react-redux";
import { IoIosClose } from "react-icons/io";
import { COLORS } from "../utils/constant";

const GenrePopup = ({ currentGenres, onApply, onClose, ...rest }) => {
  const [selectedGenres, setSelectedGenres] = useState(currentGenres || []);

  const {
    userReducer: { genres },
  } = useSelector((state) => state);

  const _handleGenreSelection = (genreId) => {
    if (selectedGenres?.includes?.(genreId)) {
      setSelectedGenres((prevState) => [
        ...(prevState?.filter((o) => o !== genreId) || []),
      ]);
    } else {
      setSelectedGenres((prevState) => [...(prevState || []), genreId]);
    }
  };

  const _onApply = () => {
    if (onApply && typeof onApply === "function") {
      onApply(selectedGenres);
    }
  };

  const _onClear = () => {
    setSelectedGenres([]);
  };

  return (
    <Modal
      {...(rest || {})}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="bg-theme-black bg-opacity-90 rounded-md"
    >
      <Modal.Body
        closeButton={true}
        style={{ backgroundColor: "black", borderRadius: ".3rem" }}
      >
        <div className="bg-white flex items-center justify-between py-2 px-3 rounded-md">
          <IoIosClose
            size={10}
            color="#fff"
            onClick={onClose}
            className="w-9 h-9 flex rounded-full border-2 border-black border-solid bg-black"
          />
          <h5 className="text-black font-bold">Select Genre</h5>
          <img src={Genre} alt="genre" className="bg-black ml-2" />
        </div>
        {genres?.loading ? (
          <div className="flex flex-1 bg-theme-black justify-center py-4 px-10 items-center">
            <div
              role="status"
              style={{ color: COLORS?.PINK }}
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : !genres?.loading && (!genres?.data || genres?.data?.length < 1) ? (
          <div className="flex flex-1 bg-theme-black justify-center py-4 px-10 items-center">
            <p className="text-2xl text-red-600 text-center">
              Sorry, genre not found!
            </p>
          </div>
        ) : (
          <>
            <div className="text-white py-3 max-h-96 overflow-auto modal-content-wrapper">
              {genres?.data?.map?.((v, i) => (
                <div
                  key={i}
                  onClick={() => _handleGenreSelection(v?.id)}
                  className="flex flex-1 px-4 py-2 items-center justify-between border-b-2 border-white cursor-pointer"
                >
                  <span className="pr-4  mt-2 items-center self-start">
                    {v?.name}
                  </span>
                  {selectedGenres?.includes?.(v?.id) && (
                    <BsCheck2 size={25} color="#fff" />
                  )}
                </div>
              ))}
            </div>
            {/* buttons  */}
            <div className="flex items-center justify-around">
              <div
                onClick={_onClear}
                className="px-14 py-2 rounded-full text-white bg-theme-black cursor-pointer"
              >
                Clear
              </div>
              <div
                onClick={_onApply}
                className="px-14 py-2 rounded-full text-black bg-white cursor-pointer"
              >
                Apply
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GenrePopup;
