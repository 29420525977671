const Error404 = () => {
  return (
    <div className="flex flex-1 justify-center flex-col items-center w-full max-w-md h-screen mx-auto px-5">
      <h1 className="text-8xl text-white lg:text-9xl">404</h1>
      <p className="text-sm text-white lg:text-base uppercase text-center mt-2">
        we are sorry, but the page you requested was not found!
      </p>
    </div>
  );
};

export default Error404;
