import React, { useEffect, useState } from "react";
import { BsClock } from "react-icons/bs";
import { BsClockFill } from "react-icons/bs";
import { getRangeBetweenDates } from "../utils/functions";
import TimetablePage from "./TimetablePage";

const Tab = ({ date, title, setIndex, activeIndex, currentIndex }) => {
  const isTabActive = currentIndex === activeIndex;

  return (
    <div
      onClick={() => setIndex(currentIndex)}
      className={`z-20 rounded-full cursor-pointer flex justify-center items-center px-3 w-[150px] mb-4 ml-3
        ${isTabActive ? "bg-white" : "bg-black"} 
        
    `}
    >
      {isTabActive ? (
        <BsClockFill size={30} color="#000" />
      ) : (
        <BsClock size={30} color="#fff" />
      )}
      <div className="flex flex-col items-center ml-3 bg-transparent py-1.5">
        <p
          className={`${
            isTabActive ? "text-black" : "text-white"
          } text-xs font-medium mb-0`}
        >
          {date}
        </p>
        <p
          className={`${
            isTabActive ? "text-black" : "text-white"
          } text-sm font-bold mt-[1px] mb-0`}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

const TimetableScrollableTabs = ({ stages, endDate, startDate }) => {
  const [activeIndex, setIndex] = useState(0);
  const [generatedDays, setGeneratedDays] = useState([]);

  useEffect(() => {
    const dates = getRangeBetweenDates(startDate, endDate);
    setGeneratedDays([...(dates || [])]);
  }, []);

  if (generatedDays?.length === 0) {
    return null;
  }

  const routes = generatedDays?.map?.((v) => ({
    key: v?.date?.replaceAll?.("/", "-"),
    title: v?.day,
    date: v?.date,
  }));

  return (
    <>
      <div className="flex flex-wrap">
        {routes?.map?.((tab, idx) => (
          <Tab
            {...tab}
            currentIndex={idx}
            setIndex={setIndex}
            activeIndex={activeIndex}
          />
        ))}
      </div>
      <TimetablePage
        stages={stages}
        timestamp={generatedDays[activeIndex]?.timestamp}
      />
    </>
  );
};

export default TimetableScrollableTabs;
