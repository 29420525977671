import React from "react";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { getImageUri } from "../utils/functions";

const Artist = ({ artist, endTime, startTime, onEditPress, onRemovePress }) => {
  return (
    <div className="w-full flex flex-row mt-4">
      <div className="w-28">
        <p className="text-xs text-white mb-0">
          {moment(startTime).format("HH:mm")} -{" "}
          {moment(endTime).format("HH:mm")}
        </p>
      </div>
      <div
        style={{ background: "#373535" }}
        className="w-full flex flex-row justify-between rounded-lg p-2"
      >
        <div className="flex flex-row flex-1 items-center">
          <img
            className="w-14 h-14 rounded-full border border-white"
            src={getImageUri(artist?.picture)}
            alt="artist"
          />
          <p className="mb-0 text-white text-sm px-2">{artist?.name}</p>
        </div>
        <div className="flex flex-row">
          <div
            onClick={onEditPress}
            className="w-5 h-5 bg-white rounded-full ml-1 flex justify-center items-center cursor-pointer"
          >
            <CiEdit size={15} color="#000" />
          </div>
          <div
            onClick={onRemovePress}
            className="w-5 h-5 bg-red-500 rounded-full ml-1 flex justify-center items-center cursor-pointer"
          >
            <IoIosClose size={20} color="#fff" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Artist;
