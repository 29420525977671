import React, { useEffect, useState } from "react";
import {
  getImageUri,
  getIntervaledTime,
  randomIdGenerator,
} from "../utils/functions";
import moment from "moment";
import Artist from "./Artist";
import { FiPlus } from "react-icons/fi";
import ArtistPopup from "./ArtistPopup";
import DivContainer from "./DivContainer";
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import { GiMicrophone } from "react-icons/gi";
import { IoCheckmarkDone } from "react-icons/io5";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { setTimetable } from "../redux/slices/appSlice";

const TimetablePage = ({ stages, timestamp }) => {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(null);
  const [endTime, setEndTime] = useState("");
  const [startTime, setStartTime] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  let timetable = useSelector((state) => state?.appReducer?.timetable);

  useEffect(() => {
    // dispatch(setTimetable({}));
  }, []);

  const _handleStartTime = (time) => {
    const intervaledTime = getIntervaledTime(time);
    setStartTime(intervaledTime);
  };

  const _handleEndTime = (time) => {
    const intervaledTime = getIntervaledTime(time);
    setEndTime(intervaledTime);
  };

  // const _checkTime = (sameStageArtists = [], START_TIME, END_TIME) => {
  //   return sameStageArtists?.some?.((o) => {
  //     const artistStartTime = o?.startTime;
  //     const artistEndTime = o?.endTime;
  //     const convertArtistStartTimeInTime = moment(artistStartTime, "x").format(
  //       "HH:mm A"
  //     );
  //     const convertArtistEndTimeInTime = moment(artistEndTime, "x").format(
  //       "HH:mm A"
  //     );

  //     if (
  //       convertArtistStartTimeInTime?.includes("PM") &&
  //       convertArtistEndTimeInTime?.includes("AM")
  //     ) {
  //       return checkTimeExistsInBetweenTwoTime(
  //         true,
  //         START_TIME,
  //         END_TIME,
  //         artistStartTime,
  //         artistEndTime
  //       );
  //     } else {
  //       return checkTimeExistsInBetweenTwoTime(
  //         false,
  //         START_TIME,
  //         END_TIME,
  //         artistStartTime,
  //         artistEndTime
  //       );
  //     }
  //   });
  // };

  const STAGES_DATA = stages?.map?.((v) => ({
    ...(v || {}),
    borderBottomWidth: false,
  }));

  const _validateArtist = () => {
    if (startTime === "") {
      alert("Start time must be required to add an artist");
      return;
    }
    if (endTime === "") {
      alert("End time must be required to add an artist");
      return;
    }
    if (selectedStage === null) {
      alert("Stage selection must be required to add an artist");
      return;
    }
    if (selectedArtist === null) {
      alert("Artist selection must be required to add an artist");
      return;
    }
    const makeDateFromTimestamp = moment(timestamp, "x").format("DD/MM/YYYY");
    const START_TIME = moment(
      `${makeDateFromTimestamp} ${moment(startTime).format("HH:mm")}`,
      "DD/MM/YYYY HH:mm"
    )
      .startOf("minutes")
      .startOf("seconds");

    const END_TIME = moment(
      `${makeDateFromTimestamp} ${moment(endTime).format("HH:mm")}`,
      "DD/MM/YYYY HH:mm"
    )
      .startOf("minutes")
      .startOf("seconds");

    // const convertStartTimeInTime = START_TIME.format("HH:mm A");
    // const convertEndTimeInTime = END_TIME.format("HH:mm A");

    // if (
    //   convertStartTimeInTime?.includes("AM") &&
    //   convertEndTimeInTime?.includes("AM") &&
    //   END_TIME.isBefore(START_TIME)
    // ) {
    //   alert("Artist end time should not be past of start time");
    //   return;
    // }

    // if (
    //   convertStartTimeInTime?.includes("PM") &&
    //   convertEndTimeInTime?.includes("PM") &&
    //   END_TIME.isBefore(START_TIME)
    // ) {
    //   alert("Artist end time should not be past of start time");
    //   return;
    // }

    // const checkAnyArtistExist = timetable?.[timestamp]?.artists;
    // if (checkAnyArtistExist && checkAnyArtistExist?.length > 0) {
    //   const filterSameStageArtists = checkAnyArtistExist?.filter?.(
    //     (o) => o?.stage?.text === selectedStage?.text
    //   );
    //   if (filterSameStageArtists && filterSameStageArtists?.length > 0) {
    //     const isTimeExists = _checkTime(
    //       filterSameStageArtists,
    //       START_TIME,
    //       END_TIME
    //     );

    //     if (isTimeExists) {
    //       alert(
    //         "Artist time of selected stage should not be matched to other artists time"
    //       );
    //       return;
    //     }
    //   }
    // }

    index !== null
      ? _handleUpdateArtist(START_TIME, END_TIME)
      : _handleAddArtist(START_TIME, END_TIME);
  };

  const _handleAddArtist = (startTime, endTime) => {
    let START_TIME = parseInt(startTime.format("x"));
    let END_TIME = parseInt(endTime.format("x"));
    // if (
    //   timetable?.[timestamp]?.artists?.find?.(
    //     (o) => o?.startTime === START_TIME && o?.stage?.id === selectedStage?.id
    //   )
    // ) {
    //   alert("This time slot has been reserved for another stage");
    //   return;
    // }
    // if (
    //   timetable?.[timestamp]?.artists?.find?.(
    //     (o) =>
    //       o?.startTime === START_TIME && o?.artist?.id === selectedArtist?.id
    //   )
    // ) {
    //   alert("This artist will be performing on different stage");
    //   return;
    // }
    let takeTimetableReplica = { ...(timetable || {}) };
    const addObject = {
      ...(takeTimetableReplica || {}),
      [timestamp]: {
        artists: [
          ...(takeTimetableReplica[timestamp]?.artists || []),
          {
            id: randomIdGenerator(20),
            endTime: END_TIME,
            startTime: START_TIME,
            stage: selectedStage,
            artist: selectedArtist,
          },
        ],
      },
    };

    dispatch(setTimetable(addObject));
    _clear();
  };

  const _handleUpdateArtist = (startTime, endTime) => {
    let START_TIME = Number(startTime.format("x"));
    let END_TIME = Number(endTime.format("x"));

    // const findStageSlotIndex = timetable?.[timestamp]?.artists?.findIndex?.(
    //   (o) => o?.startTime === START_TIME && o?.stage?.id === selectedStage?.id
    // );
    // if (findStageSlotIndex !== -1 && findStageSlotIndex !== index) {
    //   alert("This time slot has been reserved for another stage");
    //   return;
    // }
    // const findArtistSlotIndex = timetable?.[timestamp]?.artists?.findIndex?.(
    //   (o) => o?.startTime === START_TIME && o?.artist?.id === selectedArtist?.id
    // );
    // if (findArtistSlotIndex !== -1 && findArtistSlotIndex !== index) {
    //   alert("This artist will be performing on different stage");
    //   return;
    // }

    let takeTimetableReplica = { ...(timetable || {}) };
    const obj = {
      id: randomIdGenerator(20),
      endTime: END_TIME,
      startTime: START_TIME,
      stage: selectedStage,
      artist: selectedArtist,
    };

    const updatedObject = {
      ...(takeTimetableReplica || {}),
      [timestamp]: {
        artists: [obj, ...(takeTimetableReplica[timestamp]?.artists || [])],
      },
    };

    dispatch(setTimetable(updatedObject));
    _clear();
  };

  const _clear = () => {
    setIndex(null);
    setEndTime("");
    setStartTime("");
    setSelectedStage(null);
    setSelectedArtist(null);
  };

  const _removeArtist = (id) => {
    let takeTimetableReplica = { ...(timetable || {}) };
    const removeArtistObject = {
      ...(takeTimetableReplica || {}),
      [timestamp]: {
        artists: [
          ...(takeTimetableReplica?.[timestamp]?.artists?.filter(
            (o) => o?.id !== id
          ) || []),
        ],
      },
    };
    dispatch(setTimetable(removeArtistObject));
  };

  const ARTIST_DATA = timetable?.[timestamp]?.artists || [];

  const makeDataForStages = () => {
    const FILTERED_ARTISTS_BY_STAGES = [];
    STAGES_DATA?.map?.((v) => {
      const FILTER_ARTIST = ARTIST_DATA?.filter?.(
        (o) => o?.stage?.text === v?.text
      );
      FILTERED_ARTISTS_BY_STAGES.push({
        stageName: v?.text,
        artists: FILTER_ARTIST,
      });
      return null;
    });
    return FILTERED_ARTISTS_BY_STAGES;
  };

  const DATA = makeDataForStages();

  return (
    <>
      <ArtistPopup
        show={modalShow}
        selectedArtist={selectedArtist}
        onClose={() => setModalShow(false)}
        onArtistSelection={(data) => setSelectedArtist(data)}
      />
      <DivContainer>
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col justify-between">
            <div className="flex flex-1 flex-col mr-0 md:mr-3 mb-4 md:mb-0">
              <div className="flex flex-row items-center mb-2">
                <label className="items-center text-white relative">
                  Start Time
                </label>
              </div>
              <DatePicker
                showTimeInput
                dateFormat="HH:mm"
                showTimeSelectOnly
                selected={startTime}
                onChange={_handleStartTime}
                placeholderText="select the time"
              />
            </div>
            <div className="flex flex-1 flex-col">
              <div className="flex flex-row items-center mb-2">
                <label className="items-center text-white relative">
                  End Time
                </label>
              </div>
              <DatePicker
                showTimeInput
                dateFormat="HH:mm"
                selected={endTime}
                showTimeSelectOnly
                disabled={!startTime}
                onChange={_handleEndTime}
                placeholderText="select the time"
              />
            </div>
            <div className="flex flex-1 flex-col mt-4">
              <Select
                labelField="text"
                valueField="text"
                searchable={false}
                {...(selectedStage && {
                  values: [selectedStage],
                })}
                keepSelectedInList
                options={STAGES_DATA}
                placeholder="Select Stage"
                onChange={(values) => {
                  setSelectedStage(values[0]);
                }}
              />
            </div>
          </div>
          <div className="flex flex-1 flex-col justify-center items-center">
            <div
              style={{ background: "#373535" }}
              onClick={() => setModalShow(true)}
              className="w-40 h-40 rounded-lg flex flex-col justify-center items-center cursor-pointer"
            >
              <img
                alt="artist"
                src={getImageUri(selectedArtist?.picture)}
                className="w-24 h-24 rounded-full border border-white"
              />
              <p className="mt-2 mb-0 text-white text-sm">
                {selectedArtist?.name || "Search Artist"}
              </p>
            </div>
          </div>
        </div>
      </DivContainer>
      <DivContainer>
        <div
          onClick={_validateArtist}
          className="flex flex-row justify-center items-center cursor-pointer w-56 px-3 py-2.5 rounded-full bg-theme-pink self-center mt-2"
        >
          <GiMicrophone size={"25"} color="black" />
          {index != null ? (
            <>
              <p className="text-black px-3 mb-0">Update Artist</p>
              <IoCheckmarkDone size={"25"} color={"#000"} />
            </>
          ) : (
            <>
              <p className="text-black px-3 mb-0">Add Artist</p>
              <FiPlus size={"25"} color={"#000"} />
            </>
          )}
        </div>
      </DivContainer>
      <DivContainer>
        <div className="w-full">
          {DATA?.map(
            (v, i) =>
              v?.artists?.length > 0 && (
                <div key={i} className="w-full mt-2">
                  <div
                    style={{ background: "#373535" }}
                    className="py-1.5 px-3 inline-flex rounded-full"
                  >
                    <p className="text-xs mb-0 text-white">{v?.stageName}</p>
                  </div>
                  <div>
                    {v?.artists?.map?.((value, index) => (
                      <Artist
                        key={index}
                        artist={value?.artist}
                        endTime={value?.endTime}
                        startTime={value?.startTime}
                        onRemovePress={() => _removeArtist(value?.id)}
                        onEditPress={() => {
                          const findIndex = ARTIST_DATA?.findIndex?.(
                            (o) => o?.id === value?.id
                          );
                          setIndex(findIndex);
                          setStartTime(new Date(Number(value?.startTime)));
                          setEndTime(new Date(Number(value?.endTime)));
                          setSelectedArtist({
                            ...(value?.artist || {}),
                          });
                          setSelectedStage({
                            ...(value?.stage || {}),
                          });
                          _removeArtist(value?.id);
                        }}
                      />
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
      </DivContainer>
    </>
  );
};

export default TimetablePage;
