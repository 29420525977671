import {
  DEFAULT_IMAGE_URL,
  FIRESTORE,
  FIRESTORE_STORAGE,
  MAP_API_KEY,
} from "./constant";
import logo from "../Images/moertsch_white_icon.png";
import Axios from "axios";
import _ from "lodash";
import { store } from "../redux/index";
import firebase from "firebase";
import { setGenres } from "../redux/slices/userSlice";
import moment from "moment";

export const randomIdGenerator = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const _uploadFile = async (folder, file, forArray) => {
  return new Promise(async (resolve, reject) => {
    const FILE = forArray ? file?.rawFile : file;
    const extension = FILE?.name?.split?.(".").pop?.();
    const generateName = randomIdGenerator(10);
    const generatedName = generateName + "." + extension;
    const PATH = `${folder}/${generatedName}`;
    await FIRESTORE_STORAGE.ref(PATH).put(FILE);
    const downloadUrl = await FIRESTORE_STORAGE.ref(PATH).getDownloadURL();
    if (forArray) {
      resolve({
        name: generatedName,
        link: downloadUrl,
        type: file?.type,
      });
      return;
    }
    resolve(downloadUrl);
  });
};

export const uploadFileToFirebase = async (
  files,
  folder = "profile_images"
) => {
  try {
    if (!Array.isArray(files)) {
      const downloadUrl = await _uploadFile(folder, files);
      return downloadUrl;
    }
    const STORAGE_UPLOAD = await Promise.all(
      files?.map?.((v) => _uploadFile(folder, v, true))
    );
    return STORAGE_UPLOAD;
  } catch (error) {
    throw new Error(error);
  }
};

export const _mediaImg = (mediaFiles, photo) => {
  if (mediaFiles?.length > 0) {
    const imgMedia = mediaFiles.find((o) => o?.type === "photo");
    if (imgMedia) {
      return imgMedia?.link;
    }
    return logo;
  }
  return photo;
};

export const filterBetweenTheStartAndEndDate = (
  eventDate,
  startDate,
  endDate
) => {
  if (startDate && endDate) {
    if (eventDate >= startDate && eventDate <= endDate) {
      return true;
    }
    return false;
  }
  if (startDate && !endDate) {
    if (eventDate >= startDate) {
      return true;
    }
    return false;
  }
  if (!startDate && endDate) {
    if (eventDate <= endDate) {
      return true;
    }
    return false;
  }
  return true;
};

export const fixDatesInEventDataBase = async () => {
  // try {
  //   FIRESTORE.collection("clubs")
  //     .get()
  //     .then(function (querySnapshot) {
  //       console.log("querySnapshot ==> ", querySnapshot?.size);
  //       querySnapshot.forEach(async function (document, index) {
  //         await document.ref.update({
  //           name: document?.data?.()?.name?.toLowerCase?.(),
  //         });
  //         console.log(
  //           "document updated! at index ",
  //           index,
  //           " with id ==> ",
  //           document?.id
  //         );
  //       });
  //     });
  // } catch (error) {
  //   console.log("error in setDataInDataBase ==> ", error?.message);
  // }
};

export const capitalizeWords = (inputString = "") => {
  return inputString.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getLatLng = async (address, attributes = {}) => {
  if (!address) {
    return null;
  }
  try {
    const { data } = await Axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${MAP_API_KEY}`
    );
    const lat_lng = data?.results?.[0]?.geometry?.location || {};
    return {
      ...(attributes || {}),
      address,
      ...(lat_lng || {}),
    };
  } catch (error) {
    return null;
  }
};

export const saveLatLngInArr = async (arr = []) => {
  if (arr.length < 1) {
    return [];
  }
  const ARR_FOR_UPLOAD = [...arr];
  const ARR_FOR_PROMISE = [];

  arr?.map((v, i) => {
    const ADDRESS =
      v?.address ||
      v?.adress ||
      v?.Adress ||
      v?.Address ||
      v?.location ||
      v?.Location;
    if (!!ADDRESS) {
      ARR_FOR_PROMISE?.push(getLatLng(ADDRESS, v));
    } else {
      ARR_FOR_UPLOAD?.splice(i, 1);
    }
    return null;
  });

  if (ARR_FOR_PROMISE?.length < 1) {
    return [];
  }

  try {
    const DATA_WITH_LAT_LNG = await Promise.all(ARR_FOR_PROMISE);
    return DATA_WITH_LAT_LNG;
  } catch (error) {
    console.log("error in saveLatLngInArr ==> ", error?.message);
    return [];
  }
};

const _saveGenre = async (genre) => {
  return new Promise(async (resolve) => {
    const res = await FIRESTORE?.collection?.("Genres")?.add?.(genre);
    resolve({
      id: res.id,
      ...(genre || {}),
    });
  });
};

export const _saveEvent = async (event) => {
  return new Promise(async (resolve) => {
    const res = await FIRESTORE?.collection?.("events")?.add?.(event);
    resolve({
      eventId: res.id,
      ...(event || {}),
    });
  });
};

export const _saveClub = async (club) => {
  return new Promise(async (resolve) => {
    const res = await FIRESTORE?.collection?.("clubs")?.add?.(club);
    resolve({
      id: res.id,
      ...(club || {}),
    });
  });
};

const putRightGenreInArr = async (arr = [], keyName) => {
  let {
    userReducer: { genres },
  } = store?.getState();
  const NEW_ARR = [];
  arr?.map((v) => {
    const GENRE = v?.Genres || v?.genres || v?.Genre || v?.genre;
    if (!GENRE) {
      NEW_ARR?.push({
        ...(v || {}),
        genres: [],
      });
    }
    const FIND_GENRE = genres?.data?.find(
      (o) => o?.name?.toLowerCase?.() === GENRE?.toLowerCase?.()
    );
    NEW_ARR?.push({
      ...(v || {}),
      genres: [FIND_GENRE?.[keyName]],
    });
    return null;
  });
  return NEW_ARR;
};

export const saveGenresIdsInArr = async (arr = []) => {
  if (arr.length < 1) {
    return [];
  }

  // All data genres in 1 array
  const ALL_ARR_GENRES = arr
    ?.map((v) => {
      const GENRE = v?.Genres || v?.genres || v?.Genre || v?.genre;
      return !!GENRE ? GENRE?.toLowerCase?.() : "";
    })
    ?.filter((o) => o);

  // find unique genres from array
  const UNIQUE_GENRES = [...new Set(ALL_ARR_GENRES)];
  const FIND_GENRES_THAT_NEEDS_TO_BE_ADDED = [];

  if (!UNIQUE_GENRES || UNIQUE_GENRES?.length < 1) {
    return arr;
  }

  let {
    userReducer: { genres },
  } = store?.getState();

  // find unique genres from array that doesn't exists in database
  UNIQUE_GENRES?.map?.((v) => {
    const FIND_GENRE = genres?.data?.find?.(
      (o) => o?.name?.toLowerCase?.() === v
    );
    if (!FIND_GENRE) {
      FIND_GENRES_THAT_NEEDS_TO_BE_ADDED?.push({
        name: capitalizeWords(v),
        timeStamp: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
      });
    }
    return null;
  });

  if (FIND_GENRES_THAT_NEEDS_TO_BE_ADDED?.length > 0) {
    // save unique genres that don't exist in database and put them in right object
    try {
      const NEW_GENRES = await Promise.all(
        FIND_GENRES_THAT_NEEDS_TO_BE_ADDED?.map?.((v) => _saveGenre(v))
      );
      store.dispatch(
        setGenres([...(genres?.data || []), ...(NEW_GENRES || [])])
      );
      return putRightGenreInArr(arr, "name");
    } catch (error) {
      return arr;
    }
  } else {
    // put genres in right object
    return putRightGenreInArr(arr, "name");
  }
};

export const determineDateFormat = (date = "") => {
  const possibleFormats = {
    a: "YYYY-MM-DD",
    b: "MM-DD-YYYY",
    c: "DD-MM-YYYY",
    d: "YYYY/MM/DD",
    e: "MM/DD/YYYY",
    f: "DD/MM/YYYY",
    g: "MMMM D, YYYY", // September 8, 2023
    h: "MMM D, YYYY", // Sep 8, 2023
    i: "YYYY MMMM D", // 2023 September 8
    j: "D MMMM YYYY", // 8 September 2023
    k: "DD MMMM YYYY", // 08 September 2023
    l: "YYYY.MM.DD",
    m: "YYYY MMM D", // 2023 September 8
    n: "D MMM YYYY", // 8 September 2023
    o: "DD MMM YYYY", // 08 September 2023
    p: "MM.DD.YYYY",
    q: "DD.MM.YYYY",
    r: "DD MMM YY", // 08 Sep 23
    s: "YY MMM DD", // 23 Sep 08
    t: "DD-MMM-YYYY", // 08-Sep-2023
    u: "YYYY-MMM-DD", // 2023-Sep-08
    v: "D/M/YYYY", // 8/9/2023
    w: "D-M-YYYY", // 8-9-2023
    x: "x", // timestamp
    y: "YYYY-MM-DDTHH:MM:SS", // 2018-01-01T20:09:00
  };

  const getFormat = (d) => {
    for (var prop in possibleFormats) {
      if (moment(d, possibleFormats[prop], true).isValid()) {
        return possibleFormats[prop];
      }
    }
    return null;
  };

  var formatFound = getFormat(date);

  if (!!formatFound) {
    return Number(moment(date, formatFound).startOf("days").format("x"));
  }
  return "";
};

export const determineTimeFormat = (time = "") => {
  const possibleFormats = {
    a: "HH",
    b: "hh",
    c: "mm",
    d: "ss",
    e: "s",
    f: "H",
    g: "h",
    h: "h:mm",
    i: "hh:mm",
    l: "h:mm:s",
    k: "h:mm:ss",
    j: "hh:mm:ss",
    m: "H:mm",
    n: "HH:mm",
    o: "H:mm:s",
    p: "H:mm:ss",
    q: "HH:mm:ss",
    r: "h:mm a",
    s: "hh:mm a",
    t: "h:mm:s a",
    u: "h:mm:ss a",
    v: "hh:mm:ss a",
    w: "H:mm a",
    x: "HH:mm a",
    y: "H:mm:s a",
    z: "H:mm:ss a",
    A: "HH:mm:ss a",
    B: "h:mm A",
    C: "hh:mm A",
    D: "h:mm:s A",
    E: "h:mm:ss A",
    F: "hh:mm:ss A",
    G: "H:mm A",
    H: "HH:mm A",
    I: "H:mm:s A",
    J: "H:mm:ss A",
    K: "HH:mm:ss A",
  };

  const getFormat = (d) => {
    for (var prop in possibleFormats) {
      if (moment(d, possibleFormats[prop], true).isValid()) {
        return possibleFormats[prop];
      }
    }
    return null;
  };

  var formatFound = getFormat(time);

  if (!!formatFound) {
    return moment(time, formatFound).format("HH:mm");
  }
  return "";
};

export const getRangeBetweenDates = (
  startDate,
  endDate,
  format,
  lineupKeys
) => {
  let fromDate = moment(startDate, "x");
  let toDate = moment(endDate, "x");
  let diff = toDate.diff(fromDate, "day");
  let range = [];
  for (let i = 0; i < diff + 1; i++) {
    const generatedDay = moment(startDate).add(i, "day");
    const timestamp = generatedDay.startOf("day").format(format || "x");
    if (lineupKeys) {
      if (lineupKeys?.includes?.(timestamp)) {
        range.push({
          timestamp: timestamp,
          day: generatedDay.format("dddd"),
          date: generatedDay.format("DD/MM/YYYY"),
          dayText: `Day ${i + 1}`,
        });
      }
    } else {
      range.push({
        timestamp: timestamp,
        day: generatedDay.format("dddd"),
        date: generatedDay.format("DD/MM/YYYY"),
      });
    }
  }
  return range;
};

export const getIntervaledTime = (time = "", interval = 15) => {
  const convertTime = moment(time).format("HH:mm");
  const intervaledTimesSplit = ["00", "15", "30", "45"];
  const splitTime = convertTime?.split?.(":");
  if (intervaledTimesSplit?.includes?.(splitTime?.[1])) {
    return time;
  }
  let ROUNDING = interval * 60 * 1000;
  let start = moment();
  // floor function for previous interval
  // ceil function for next interval
  start = moment(Math.floor(+start / ROUNDING) * ROUNDING);
  return new Date(start.format());
};

export const getImageUri = (uri) => {
  if (!!!uri) {
    return DEFAULT_IMAGE_URL;
  }
  const COND =
    uri !== null &&
    uri !== undefined &&
    typeof uri === "string" &&
    uri?.includes?.("/") &&
    uri?.includes?.(".");
  if (COND) {
    return uri;
  }
  return DEFAULT_IMAGE_URL;
};

export const makeTimetableDataForEventDatabase = (timetable = {}) => {
  let { stages, userIdsWhoLikedArtists, artists, ...rest } = timetable || {};
  const STAGES_NAME = stages?.map?.((v) => v?.text);
  const ARTISTS_IDS = [];
  const REST = rest || {};
  const LINE_UP = {};
  Object?.keys?.(REST)?.forEach?.(function (key) {
    const element = REST?.[key];
    if (element) {
      const extracted_artists = element?.artists?.map?.((v) => v?.artist?.id);
      ARTISTS_IDS?.push?.(...(extracted_artists || []));
      LINE_UP[key] = {
        date: Number(key),
        artists: [
          ...(element?.artists?.map?.((v) => ({
            uniqueId: v?.id,
            endTime: v?.endTime,
            stage: v?.stage?.text,
            startTime: v?.startTime,
            artistId: v?.artist?.id,
            userIdsWhoStarredThisArtist: [],
          })) || []),
        ],
      };
    }
  });
  const FILTER_ARTIST_IDS = [...(new Set(ARTISTS_IDS) || [])];
  return {
    lineup: LINE_UP,
    stages: STAGES_NAME,
    artists: FILTER_ARTIST_IDS,
    userIdsWhoLikedArtists: userIdsWhoLikedArtists || [],
  };
};

export const fetchEventTimetable = async (paramsEventDetails = {}) => {
  try {
    if (
      paramsEventDetails?.timetable &&
      !_.isEmpty(paramsEventDetails?.timetable)
    ) {
      const IDS = paramsEventDetails?.timetable?.artists?.[0]?.id
        ? [
            ...(paramsEventDetails?.timetable?.artists?.map?.((o) => o?.id) ||
              []),
          ]
        : [...(paramsEventDetails?.timetable?.artists || [])];
      const artistRequest = await FIRESTORE.collection("artists")
        .where(firebase?.firestore?.FieldPath?.documentId?.(), "in", IDS)
        .get();
      if (artistRequest?.size > 0) {
        const ARTIST = [];
        artistRequest?.forEach((response) => {
          if (response?.exists) {
            ARTIST?.push({
              id: response.id,
              ...(response?.data() || {}),
            });
          }
        });
        const fetchedTimetable = {
          ...(paramsEventDetails?.timetable || {}),
          artists: ARTIST || [],
        };
        return fetchedTimetable;
      }
      return null;
    }
    return null;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const makeDatabaseTimetableDataForEventData = (timetable = {}) => {
  let { stages, artists, lineup } = timetable || {};
  const STAGES = stages?.map?.((v, i) => ({
    id: i + 1,
    text: v,
  }));
  const DATES = Object?.keys?.(lineup);
  const LINE_UP = {};
  DATES?.forEach?.(function (key) {
    const element = lineup?.[key];
    if (element) {
      LINE_UP[key] = {
        artists: [
          ...(element?.artists?.map?.((v) => {
            const foundStage = STAGES?.find?.((o) => o?.text === v?.stage);
            const foundArtist = artists?.find?.((o) => o?.id === v?.artistId);
            return {
              id: v?.uniqueId,
              stage: foundStage,
              artist: foundArtist,
              endTime: Number(v?.endTime),
              startTime: Number(v?.startTime),
            };
          }) || []),
        ],
      };
    }
  });
  return {
    stages: STAGES,
    ...(artists && {
      artists,
    }),
    ...(LINE_UP || {}),
  };
};
