import React from "react";
import { useSelector } from "react-redux";
import { FaLockOpen } from "react-icons/fa";
import EventBox from "../../../Components/EventBox";
import { COLORS } from "../../../utils/constant";

const ValidateEvent = () => {
  const {
    userReducer: { events },
  } = useSelector((state) => state);

  const DATA =
    events?.data?.filter?.(
      (o) => o?.status === "pending" || o?.status === "updating"
    ) || [];
  const STATUS = events?.status;

  return STATUS === "loading" ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex flex-1 h-full container py-4 justify-center items-center">
        <div
          role="status"
          style={{ color: COLORS?.PINK }}
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : STATUS === "success" && DATA?.length > 0 ? (
    <div className="pb-4 container">
      <div className="ml-5 pr-14 pb-2 inline-flex items-center border-b-2 self-start border-gray-300">
        <p className="text-white inline mb-0 pb-0 capitalize">
          Event to Validate
        </p>
        <FaLockOpen size={25} color="#fff" className="ml-4" />
      </div>

      {/*  lg:justify-start */}
      <div className="flex flex-wrap justify-center mt-5">
        {DATA.map((v, i) => {
          return <EventBox key={i} {...(v || {})} verify />;
        })}
      </div>
    </div>
  ) : (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="container h-full py-4 flex flex-1 justify-center items-center">
        <p className="text-2xl text-red-600 text-center">
          No event found for validation!
        </p>
      </div>
    </div>
  );
};

export default ValidateEvent;
