import React from "react";
import { AiFillCamera } from "react-icons/ai";
import { BsCloudUpload, BsFiletypeJson } from "react-icons/bs";

const FileSelector = ({ 
  file, 
  accept, 
  setFile, 
  fileName,
  setRawFile, 
  placeholder, 
  type = "image", 
}) => {
  const IS_IMAGE = type === "image";

  const _onFilePick = (event) => {
    if (event?.target?.files && event?.target?.files?.[0]) {
      let fl = event?.target?.files?.[0];
      if (!fl) return;

      let reader = new FileReader();
      if(!IS_IMAGE){
        reader.readAsText(fl, "UTF-8");
      }
      reader.onload = (e) => {
        if (setFile && typeof setFile === "function") {
          setFile(e?.target?.result);
        }
        if (setRawFile && typeof setRawFile === "function") {
          setRawFile(fl);
        }
      };
      if (IS_IMAGE) {
         reader.readAsDataURL(event?.target?.files?.[0]);
      }
    }
  };

  const PLACE_HOLDER = placeholder || "Select Image Here";
  const ACCEPT = IS_IMAGE ? "image/png, image/jpg, image/jpeg, image/webp" : accept || "*";
  const CHANGE_ICON = IS_IMAGE ? <AiFillCamera size={20} color="white" /> : <BsCloudUpload size={20} color="white"/>;

  return (
    <div className="max-w-2xl rounded-lg shadow-xl bg-theme-black">
      {!!file ? (
        <div className="flex items-center justify-center h-56 w-full relative">
          <div className="absolute top-3 right-3 rounded-full w-10 h-10 flex justify-center items-center bg-theme-black">
            <label
              htmlFor="retake-img"
              className="cursor-pointer rounded-full w-full h-full flex justify-center items-center shadow-xl"
            >
              {CHANGE_ICON}
              <input
                type="file"
                id="retake-img"
                accept={ACCEPT}
                className="hidden"
                onChange={_onFilePick}
              />
            </label>
          </div>
          {IS_IMAGE ? (
            <img
              alt="event"
              src={file}
              className="w-full h-full rounded-lg"
            />
          ): (
            <div className="h-auto flex flex-col items-center justify-center w-auto object-contain">
              <BsFiletypeJson size={70} color="white" />
              {!!fileName && (
                <p className="mt-2 mb-0 text-white text-sm">
                  {fileName}
                </p>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center h-56 w-full">
          <label
            htmlFor="take-img"
            className="flex flex-col w-full h-full justify-center items-center cursor-pointer"
          >
            <div className="py-10 flex flex-col w-full h-full justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-10 h-10 text-white "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
              <p className="pt-1 text-lg tracking-wider text-white ">
                {PLACE_HOLDER}
              </p>
            </div>
            <input
              multiple
              type="file"
              id="take-img"
              accept={ACCEPT}
              className="hidden"
              onChange={_onFilePick}
            />
          </label>
        </div>
      )}
    </div>
  );
};

export default FileSelector;
