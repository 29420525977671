import { createSlice } from "@reduxjs/toolkit";
import {
  getClubs,
  getUsers,
  getEvents,
  getGenres,
  getReports,
  getTickets,
} from "../actions/userAction";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    reports: {
      status: "loading",
      data: [],
    },
    genres: {
      status: "loading",
      data: [],
    },
    clubs: {
      status: "loading",
      data: [],
    },
    events: {
      status: "loading",
      data: [],
    },
    users: {
      status: "loading",
      data: [],
    },
    tickets: {
      status: "loading",
      data: [],
    },
    userData: null,
  },
  reducers: {
    clearUser: (state) => {
      state.userData = null;
    },
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    clearGenres: (state) => {
      state.genres = {
        status: "loading",
        data: [],
      };
    },
    setGenres: (state, action) => {
      state.genres = {
        status: "success",
        data: action.payload,
      };
    },
    clearClubs: (state) => {
      state.clubs = {
        status: "loading",
        data: [],
      };
    },
    setClubs: (state, action) => {
      state.clubs = {
        status: "success",
        data: action.payload,
      };
    },
    clearEvents: (state) => {
      state.events = {
        status: "loading",
        data: [],
      };
    },
    setEvents: (state, action) => {
      state.events = {
        status: "success",
        data: action.payload,
      };
    },
    setTickets: (state, action) => {
      state.events = {
        status: "success",
        data: action.payload,
      };
    },
    clearReports: (state) => {
      state.reports = {
        status: "loading",
        data: [],
      };
    },
    setReports: (state, action) => {
      state.reports = {
        status: "success",
        data: action.payload,
      };
    },
    clearUsers: (state) => {
      state.users = {
        status: "loading",
        data: [],
      };
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
  extraReducers: {
    [getGenres.pending]: (state) => {
      state.genres.status = "loading";
    },
    [getGenres.fulfilled]: (state, action) => {
      state.genres.status = "success";
      state.genres.data = action?.payload;
    },
    [getGenres.rejected]: (state) => {
      state.genres.status = "failed";
    },

    [getClubs.pending]: (state) => {
      state.clubs.status = "loading";
    },
    [getClubs.fulfilled]: (state, action) => {
      state.clubs.status = "success";
      state.clubs.data = action?.payload;
    },
    [getClubs.rejected]: (state) => {
      state.clubs.status = "failed";
    },

    [getUsers.pending]: (state) => {
      state.users.status = "loading";
    },
    [getUsers.fulfilled]: (state, action) => {
      state.users.status = "success";
      state.users.data = action?.payload;
    },
    [getUsers.rejected]: (state) => {
      state.users.status = "failed";
    },

    [getEvents.pending]: (state) => {
      state.events.status = "loading";
    },
    [getEvents.fulfilled]: (state, action) => {
      state.events.status = "success";
      state.events.data = action?.payload;
    },
    [getEvents.rejected]: (state) => {
      state.events.status = "failed";
    },

    [getReports.pending]: (state) => {
      state.reports.status = "loading";
    },
    [getReports.fulfilled]: (state, action) => {
      state.reports.status = "success";
      state.reports.data = action?.payload;
    },
    [getReports.rejected]: (state) => {
      state.reports.status = "failed";
    },

    [getTickets.pending]: (state) => {
      state.tickets.status = "loading";
    },
    [getTickets.fulfilled]: (state, action) => {
      state.tickets.status = "success";
      state.tickets.data = action?.payload;
    },
    [getTickets.rejected]: (state) => {
      state.tickets.status = "failed";
    },
  },
});

export const {
  setUser,
  setTickets,
  setMyEvents,
  clearMyEvents,
  clearUser,
  clearGenres,
  clearEvents,
  setEvents,
  setGenres,
  clearClubs,
  setClubs,
  clearLiked,
  setLikedClubs,
  setLikedEvents,
  setNotification,
  clearNotification,
  clearReports,
  setReports,
} = userSlice.actions;

export default userSlice.reducer;
