import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IoIosClose } from "react-icons/io";
import { GiMicrophone } from "react-icons/gi";
import { getImageUri } from "../utils/functions";
import { GoCheckCircleFill } from "react-icons/go";
import { COLORS, FIRESTORE } from "../utils/constant";

const ArtistPopup = ({
  onClose,
  selectedArtist,
  onArtistSelection,
  ...rest
}) => {
  const [artist, setArtist] = useState(null);
  const [allArtists, setAllArtists] = useState({
    status: "loading",
    data: [],
  });
  const [searchTxt, setSearchTxt] = useState("");
  const {
    userReducer: { genres },
  } = useSelector((state) => state);

  useEffect(() => {
    if (selectedArtist) {
      setArtist({ ...selectedArtist });
    }
    _fetchArtist();
  }, []);

  const _handleSearch = (text) => {
    const TXT = text?.trim?.();
    setSearchTxt(text);
    _fetchArtist(TXT);
  };

  const _fetchArtist = async (text = "", passConditions) => {
    try {
      if (passConditions ? true : text === "") {
        const request = await FIRESTORE.collection("artists")
          .orderBy("name", "asc")
          .limit(12)
          .get();
        sortArtistData(request);
        return;
      }
      if (passConditions ? true : text !== "") {
        const TXT = passConditions ? searchTxt : text;
        const request = await FIRESTORE.collection("artists")
          .where("name", ">=", TXT?.toLowerCase?.())
          .where("name", "<=", TXT?.toLowerCase?.() + "~")
          .orderBy("name", "asc")
          .get();
        sortArtistData(request);
        return;
      }
    } catch (error) {
      console.log("error in fetching artists ===> ", error?.message);
      setAllArtists((prevState) => ({
        ...(prevState || {}),
        status: "failed",
      }));
    }
  };

  const sortArtistData = async (request) => {
    try {
      const data = [];
      if (request?.size > 0) {
        request?.forEach?.((v) => {
          if (v?.exists && v?.id !== selectedArtist?.id) {
            data.push({
              id: v?.id,
              ...(v?.data?.() || {}),
            });
          }
        });
      }
      setAllArtists({
        status: "success",
        data: selectedArtist ? [selectedArtist, ...(data || [])] : data,
      });
    } catch (error) {}
  };

  const DATA = allArtists?.data || [];

  return (
    <Modal
      {...(rest || {})}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      className="bg-theme-black bg-opacity-90 rounded-md"
    >
      <Modal.Body
        closeButton={true}
        style={{ backgroundColor: "black", borderRadius: ".3rem" }}
      >
        <div className="bg-white flex items-center justify-between py-2 px-3 rounded-md">
          <IoIosClose
            size={10}
            color="#fff"
            onClick={onClose}
            className="w-9 h-9 flex rounded-full border-2 border-black border-solid bg-black"
          />
          <h5 className="text-black font-bold">Select Artist</h5>
          <GiMicrophone size={"30"} color="black" />
        </div>
        {allArtists?.status === "loading" ? (
          <div className="flex flex-1 bg-theme-black justify-center py-4 px-10 items-center">
            <div
              role="status"
              style={{ color: COLORS?.PINK }}
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : allArtists?.loading === "failed" && (!DATA || DATA?.length < 1) ? (
          <div className="flex flex-1 bg-theme-black justify-center py-4 px-10 items-center">
            <p className="text-2xl text-red-600 text-center">
              Something went wrong!
            </p>
          </div>
        ) : allArtists?.loading === "success" && (!DATA || DATA?.length < 1) ? (
          <div className="flex flex-1 bg-theme-black justify-center py-4 px-10 items-center">
            <p className="text-2xl text-red-600 text-center">
              {!!searchTxt?.trim() ? "No search found!" : "No artists found!"}
            </p>
          </div>
        ) : (
          <>
            <div
              style={{ maxHeight: "70vh" }}
              className="text-white py-3 overflow-auto modal-content-wrapper"
            >
              <input
                name="search"
                type="text"
                value={searchTxt}
                placeholder={"Search artist here..."}
                style={{ background: "#373535" }}
                onChange={({ target }) => _handleSearch(target?.value)}
                className="mb-3 w-11/12 text-white py-2.5 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0 mx-auto flex self-center"
              />
              <div className="grid grid-cols-5 gap-3">
                {DATA?.map?.((item, id) => {
                  const foundGenre = genres?.data?.find?.(
                    (o) => o?.id === item?.genre
                  );
                  return (
                    <div
                      key={id}
                      style={{ background: "#373535" }}
                      onClick={() => setArtist(item)}
                      className="w-full flex flex-col justify-between rounded-lg relative cursor-pointer"
                    >
                      <div className="absolute top-2 right-2">
                        <GoCheckCircleFill
                          size={20}
                          color={
                            artist?.id === item?.id ? "#1c7ffd" : "#9ca3af"
                          }
                        />
                      </div>
                      <img
                        className="w-full h-32 rounded-lg"
                        src={getImageUri(item?.picture)}
                        alt="artist"
                      />
                      <div>
                        <p className="my-2.5 text-white text-sm px-2 text-center manage-text-overflow">
                          {item?.name}
                        </p>
                        {foundGenre && (
                          <div className="px-2 text-center w-4/5 rounded-md mx-auto bg-gray-600 mb-2">
                            <p className=" text-white text-xs px-2 py-0.5 text-center manage-text-overflow">
                              {foundGenre?.name}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* buttons  */}
            <div className="flex items-center justify-around">
              <div
                onClick={() => {
                  setArtist(null);
                  onArtistSelection(null);
                }}
                className="px-14 py-2 rounded-full text-white bg-theme-black cursor-pointer"
              >
                Clear
              </div>
              <div
                onClick={() => {
                  onArtistSelection(artist);
                  onClose();
                }}
                className="px-14 py-2 rounded-full text-black bg-white cursor-pointer"
              >
                Select
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ArtistPopup;
