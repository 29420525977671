import { createSlice } from "@reduxjs/toolkit";
import { getConfig } from "../actions/appActions";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    alert: null,
    search: null,
    eventType: "",
    deepLinking: null,
    generatedDates: [],
    calendarSearch: null,
    config: {
      status: "loading",
      data: {},
    },
    timetable: {},
  },
  reducers: {
    resetAlert: (state) => {
      state.alert = null;
    },
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    resetDeepLinking: (state) => {
      state.deepLinking = null;
    },
    setDeepLinking: (state, action) => {
      state.deepLinking = action.payload;
    },
    resetEventType: (state) => {
      state.eventType = null;
    },
    setEventType: (state, action) => {
      state.eventType = action.payload;
    },
    resetGeneratedDates: (state) => {
      state.generatedDates = [];
    },
    setGeneratedDates: (state, action) => {
      state.generatedDates = action.payload;
    },
    resetSearch: (state) => {
      state.search = null;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    resetCalendarSearch: (state) => {
      state.calendarSearch = null;
    },
    setCalendarSearch: (state, action) => {
      state.calendarSearch = action.payload;
    },
    resetTimetable: (state) => {
      state.timetable = {};
    },
    setTimetable: (state, action) => {
      state.timetable = action.payload;
    },
  },
  extraReducers: {
    // config
    [getConfig.pending]: (state) => {
      state.config.status = "loading";
    },
    [getConfig.fulfilled]: (state, action) => {
      state.config.status = "success";
      state.config.data = action?.payload;
    },
    [getConfig.rejected]: (state) => {
      state.config.status = "failed";
    },
  },
});

export const {
  setAlert,
  setSearch,
  resetAlert,
  resetSearch,
  setEventType,
  setTimetable,
  setDeepLinking,
  resetEventType,
  resetTimetable,
  resetDeepLinking,
  setCalendarSearch,
  setGeneratedDates,
  resetGeneratedDates,
  resetCalendarSearch,
} = appSlice.actions;

export default appSlice.reducer;
