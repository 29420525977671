import React, { useState } from "react";
import {
  FIRESTORE,
  FIREBASE_SDK,
  FIRESTORE_AUTH,
  DEFAULT_ERROR_MESSAGE,
} from "../../utils/constant";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import logo from "../../Images/screen.png";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/slices/userSlice";
import { useGoogleLogin, googleLogout } from "@react-oauth/google";

function Signin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const checkIsEmailExists = async (uid, email) => {
    try {
      if (!email) {
        googleLogout();
        alert("Signin failed! Please try again!");
        return;
      }
      const response = await FIRESTORE.collection("Users")
        .where("type", "==", "admin")
        .where("email", "==", email)
        .where("providers", "array-contains", "gmail")
        .get();
      if (response.size > 0 && response.size < 2) {
        response.forEach((snap) => {
          const data = snap.data();
          _saveUserAndRedirect(uid, data);
          return;
        });
        return;
      }
      googleLogout();
      alert("You are not authorized to use admin panel");
      return;
    } catch (error) {
      console.log("error in checkIsEmailExists ===> ", {
        error: error?.message,
      });
      setLoading(false);
      googleLogout();
      alert(error?.message || DEFAULT_ERROR_MESSAGE);
    }
  };

  const responseGoogleSuccess = async (access_token) => {
    try {
      setLoading(true);
      const googleCredential =
        new FIREBASE_SDK.auth.GoogleAuthProvider.credential(null, access_token);
      const {
        user: { uid, email },
      } = await FIRESTORE_AUTH.signInWithCredential(googleCredential);
      checkIsEmailExists(uid, email);
    } catch (error) {
      googleLogout();
      alert("Signin failed! Please try again!");
    }
  };

  const _saveUserAndRedirect = async (uid, responseData) => {
    dispatch(
      setUser({
        userId: uid,
        ...(responseData || {}),
      })
    );
    navigate("/", {
      replace: true,
    });
  };

  const responseGoogleFailed = (response) => {
    console.log(response);
    alert("Signin failed! Please try again!");
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      responseGoogleSuccess(tokenResponse?.access_token),
    onError: responseGoogleFailed,
  });

  return (
    <div className="flex justify-center flex-col items-center h-screen w-full mx-auto container">
      <img src={logo} alt="Logo" className="w-60 h-60 object-contain mb-10" />
      <button
        disabled={loading}
        onClick={() => login()}
        className="flex cursor-pointer items-center justify-center border-none bg-white rounded-full w-72 py-1"
      >
        <span className="w-10">
          <FcGoogle size={28} />
        </span>
        <p className="ml-1 mt-3 text-black">Continue with Google</p>
      </button>
    </div>
  );
}

export default Signin;
