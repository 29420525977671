import React, { useState } from "react";
import qs from "qs";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { FiLink } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DivContainer from "./DivContainer";
import { IoIosClose } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { _mediaImg } from "../utils/functions";
import { VscChromeClose } from "react-icons/vsc";
import { setEvents } from "../redux/slices/userSlice";
import { AiOutlineNotification } from "react-icons/ai";
import { RiDeleteBin5Fill, RiFileCopy2Line } from "react-icons/ri";
import { FIREBASE_FUNCTION_URL, FIRESTORE } from "../utils/constant";
import { BsCheck2, BsFillCalendarEventFill, BsPencil } from "react-icons/bs";

const EventBox = ({
  title,
  media,
  photo,
  status,
  verify,
  endDate,
  endTime,
  userId,
  onClick,
  eventId,
  location,
  startDate,
  startTime,
  affiliateLink,
  allowValidation,
  ...rest
}) => {
  const dispatch = useDispatch();
  const {
    userReducer: { events },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationTitleError, setNotificationTitleError] = useState(false);
  const [notificationMessageError, setNotificationMessageError] =
    useState(false);

  const _handleVerification = async (status) => {
    setLoading(true);
    await FIRESTORE.collection("events").doc(eventId).update({
      status,
    });
    await FIRESTORE.collection("notifications").add({
      userId,
      title,
      id: eventId,
      // needs to be updated
      ...(!!photo && {
        image: photo || [],
      }),
      type: "event",
      message:
        status === "rejected"
          ? "Your event got rejected!"
          : "Your event got approved!",
    });

    const findAllEventIndex = events?.data?.findIndex?.(
      (o) => o?.eventId === eventId
    );
    const findEvent = events?.data?.find?.((o) => o?.eventId === eventId);

    let takeAllEventsReplica = [...(events?.data || [])];
    takeAllEventsReplica[findAllEventIndex] = {
      ...(findEvent || {}),
      status,
    };
    dispatch(setEvents([...(takeAllEventsReplica || [])]));
    setLoading(false);
  };

  const _handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _handleDeleteEvent();
      }
    });
  };

  const _handleDeleteEvent = async () => {
    setLoading(true);
    await FIRESTORE.collection("events").doc(eventId).delete();
    // needs to be updated
    await FIRESTORE.collection("notifications").add({
      title,
      userId,
      ...(!!photo && {
        image: photo || [],
      }),
      message: "Your event got deleted by admin!",
    });

    const filteredEvents = events?.data?.filter?.(
      (o) => o?.eventId !== eventId
    );

    dispatch(setEvents([...(filteredEvents || [])]));
    setLoading(false);
  };

  const _sendNotification = async () => {
    setNotificationTitleError(true);
    setNotificationMessageError(true);

    if (notificationTitle && notificationMessage) {
      try {
        setModalLoading(true);
        const params = {
          type: "event",
          id: eventId,
          title: notificationTitle,
          message: notificationMessage,
        };
        const response = await fetch(
          `${FIREBASE_FUNCTION_URL}/sendNotificationToTheUsersWhoLikedThatEventOrClub`,
          {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, *cors, same-origin
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(params), // body data type must match "Content-Type" header
          }
        );
        const data = await response?.text();
        setModalVisibility(false);
        Swal.fire({
          icon: "success",
          title: "Sent!",
          text: `Notification has been sent to all users that liked "${title}" event`,
          allowOutsideClick: false,
        });
      } catch (error) {
        console.log("error ===> ", error?.message);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Sorry, something went wrong. Please try again!",
        });
      } finally {
        setModalLoading(false);
      }
    }
  };

  // console.log("media ==> ", media);

  return (
    <div
      onClick={onClick}
      className={`rounded-3xl w-full sm:w-96 mr-0 sm:mr-5 mt-4 relative ${
        allowValidation
          ? status === "approved"
            ? "bg-green-900"
            : status === "rejected"
            ? "bg-red-900"
            : "bg-theme-black"
          : "bg-theme-black"
      }`}
    >
      <Modal
        // size=""
        centered
        show={modalVisibility}
        onClose={() => setModalVisibility(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="bg-theme-black bg-opacity-90 rounded-md"
      >
        <Modal.Body
          closeButton={true}
          style={{ backgroundColor: "black", borderRadius: ".3rem" }}
        >
          <div className="flex items-center justify-end">
            <IoIosClose
              size={40}
              color="#fff"
              onClick={() => setModalVisibility(false)}
            />
          </div>

          <>
            <div className="text-white py-3  overflow-auto modal-content-wrapper">
              <h5 className="text-white text-center font-bold mt-2 px-3">
                Send Notification to "{title}'s" users
              </h5>
              {/* <div
                    className="flex flex-1 px-4 py-2"
                  >
                   
                  </div> */}
              <DivContainer>
                <div className="flex flex-row items-center">
                  <label className="items-center text-white relative">
                    Notification Title
                    <div className="absolute top-0 -right-3">
                      <span className="text-red-600 text-xl">*</span>
                    </div>
                  </label>
                </div>
                <input
                  name="title"
                  type="text"
                  value={notificationTitle}
                  onChange={({ target }) => setNotificationTitle(target?.value)}
                  className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
                />
                {notificationTitleError && notificationTitle === "" && (
                  <p className="mt-3 mb-0 text-red-600 text-sm">
                    Notification title must be required
                  </p>
                )}
              </DivContainer>

              <DivContainer>
                <div className="flex flex-row items-center">
                  <label className="items-center text-white relative">
                    Message
                    <div className="absolute top-0 -right-3">
                      <span className="text-red-600 text-xl">*</span>
                    </div>
                  </label>
                </div>
                <textarea
                  type="text"
                  name="message"
                  value={notificationMessage}
                  style={{ resize: "none" }}
                  onChange={({ target }) =>
                    setNotificationMessage(target?.value)
                  }
                  className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
                ></textarea>
                {notificationMessageError && notificationMessage === "" && (
                  <p className="mt-3 mb-0 text-red-600 text-sm">
                    Notification message must be required
                  </p>
                )}
              </DivContainer>
            </div>
            <div className="flex items-center justify-around mt-2 pb-2">
              <button
                disabled={modalLoading}
                onClick={() => (!modalLoading ? _sendNotification() : {})}
                className="w-52 py-2 text-xl font-semibold text-center mt-3 rounded-full text-black bg-white cursor-pointer"
              >
                {modalLoading ? (
                  <div
                    role="status"
                    style={{ width: "1.3rem", height: "1.3rem" }}
                    className="spinner-border animate-spin inline-block border-2 text-center text-black rounded-full"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Apply"
                )}
              </button>
            </div>
          </>
        </Modal.Body>
      </Modal>
      {loading && (
        <div className="rounded-3xl absolute z-30 bg-white bg-opacity-25 w-full h-full flex items-center justify-center">
          <div
            role="status"
            style={{ width: "1.65rem", height: "1.65rem" }}
            className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="flex justify-start flex-col w-full h-full p-3 sm:p-4">
        <div className="flex justify-between items-start">
          <img
            alt="Logo"
            src={_mediaImg(media, photo)}
            className="w-28 h-20 rounded-2xl bg-gray-500"
          />
          <div>
            {!verify && (
              <>
                <div className="flex space-x-3">
                  <Link to={`/event/${eventId}`}>
                    <div className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-white border-solid cursor-pointer">
                      <BsPencil size={20} color="#ffffff" />
                    </div>
                  </Link>
                  <div
                    onClick={() => !loading && _handleDeleteConfirmation()}
                    className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-red-500 border-solid cursor-pointer"
                  >
                    <RiDeleteBin5Fill size={20} color="rgb(239, 68, 68)" />
                  </div>
                </div>
                <div className="flex space-x-3 mt-3">
                  <div
                    onClick={() => setModalVisibility(true)}
                    className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-green-400 border-solid cursor-pointer"
                  >
                    <AiOutlineNotification
                      size={20}
                      color="rgb(52, 211, 153)"
                    />
                  </div>
                  <Link
                    to={`/event`}
                    state={{
                      data: {
                        title,
                        photo,
                        status,
                        endDate,
                        endTime,
                        userId,
                        onClick,
                        eventId,
                        location,
                        startDate,
                        startTime,
                        affiliateLink,
                        ...(rest || {}),
                      },
                    }}
                  >
                    <div className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-blue-400 border-solid cursor-pointer">
                      <RiFileCopy2Line size={20} color="rgb(96, 165, 250)" />
                    </div>
                  </Link>
                </div>
              </>
            )}
            {verify && (
              <div className="flex space-x-3">
                <div
                  onClick={() => !loading && _handleVerification("approved")}
                  className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-green-400 border-solid cursor-pointer"
                >
                  <BsCheck2 size={20} color="rgba(52, 211, 153)" />
                </div>
                <div
                  onClick={() => !loading && _handleVerification("rejected")}
                  className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-red-500 border-solid cursor-pointer"
                >
                  <VscChromeClose size={20} color="rgb(239, 68, 68)" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="text-white mb-0 pb-0 mt-3 text-xl font-semibold">
            {title}
          </p>
          <div className="flex mt-2">
            <span className="w-6">
              <MdLocationOn size={20} color="#ffffff" />
            </span>{" "}
            <p className="text-white pl-2 text-sm">{location?.address}</p>
          </div>
          <div className="flex">
            <span className="w-6">
              <BsFillCalendarEventFill size={18} color="#ffffff" />{" "}
            </span>
            <p className="text-white pl-2 text-sm">
              {moment(startDate, "x").format("DD/MM/YYYY")}
              {`${
                endDate && endDate !== startDate
                  ? `-${moment(endDate, "x").format("DD/MM/YYYY")}`
                  : ""
              }`}
              {`${startTime ? `  -  ${startTime}` : ""}`}
              {`${endTime ? `-${endTime}` : ""}`}
            </p>
          </div>
          {affiliateLink !== "" &&
            affiliateLink !== undefined &&
            affiliateLink !== null && (
              <div className="flex">
                <span className="w-6">
                  <FiLink size={20} color="#ffffff" />
                </span>{" "}
                <a
                  href={affiliateLink}
                  target="_blank"
                  className="text-blue-400 pl-2 text-sm break-all"
                >
                  {affiliateLink}
                </a>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default EventBox;
