import React, { useState } from "react";
import Swal from "sweetalert2";
import firebase from "firebase";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BsPencil, BsTrash3 } from "react-icons/bs";
import { COLORS, FIRESTORE } from "../../utils/constant";
import { setGenres } from "../../redux/slices/userSlice";

const GenreBox = ({
  name,
  id,
  onEditPress,
  loading,
  genreId,
  onDeletePress,
}) => {
  return (
    <div
      style={{ maxWidth: 400 }}
      className={`flex justify-between items-center ${
        genreId === id ? "bg-theme-pink" : "bg-theme-black"
      } rounded-3xl mr-5 p-3 sm:p-4 mt-4 relative`}
    >
      {loading && (
        <div className="rounded-3xl top-0 left-0 absolute z-30 bg-white bg-opacity-25 w-full h-full flex items-center justify-center mr-5"></div>
      )}
      <div className="flex flex-1 pl-5 pr-5">
        <p className="text-white mb-0 text-xl font-semibold">{name}</p>
      </div>
      <div className="flex">
        <button
          disabled={loading}
          onClick={() =>
            !loading &&
            onEditPress({
              id,
              name,
            })
          }
          className="w-8 h-8 flex justify-center items-center rounded-full border-2 border-white border-solid cursor-pointer"
        >
          <BsPencil size={12} color="#ffffff" />
        </button>
        <button
          disabled={loading}
          onClick={() =>
            !loading &&
            onDeletePress({
              id,
              name,
            })
          }
          className="w-8 h-8 ml-2 flex justify-center items-center rounded-full border-2 border-white border-solid cursor-pointer"
        >
          <BsTrash3 size={12} color="#ffffff" />
        </button>
      </div>
    </div>
  );
};

const Genres = () => {
  let {
    userReducer: { genres },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [genreId, setGenreId] = useState("");
  const [loading, setLoading] = useState(false);

  const DATA = genres?.data || [];
  const STATUS = genres?.status;

  const _validate = (e) => {
    e?.preventDefault?.();
    const trimText = text?.toLowerCase?.()?.trim?.();
    if (trimText === "") {
      return;
    }
    const searchResults = genres?.data?.filter?.(
      (o) => o?.name?.toLowerCase?.() === trimText
    );
    if (searchResults?.length > 0) {
      alert(`${text} already exists`);
      return;
    }
    if (genreId !== "") {
      _updateGenre(trimText);
      return;
    }
    _addGenre(trimText);
  };

  const onEditPress = (params) => {
    setText(params?.name);
    setGenreId(params?.id);
  };

  const _addGenre = async () => {
    try {
      setLoading(true);
      const newGenre = {
        name: text,
        timeStamp: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
      };
      const doc = await FIRESTORE.collection("Genres").add(newGenre);
      newGenre.id = doc.id;
      dispatch(setGenres([...(genres?.data || []), newGenre]));
      setText("");
      setLoading(false);
      Swal.fire({
        title: "Added!",
        icon: "success",
        text: `New genre added successfully!`,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error?.message || `Something went wrong!`,
      });
    } finally {
      setLoading(false);
    }
  };

  const _updateGenre = async () => {
    try {
      setLoading(true);
      await FIRESTORE.collection("Genres").doc(genreId).update({
        name: text,
      });
      const findIndex = genres?.data?.findIndex?.((o) => o?.id === genreId);

      let takeAllGenresReplica = [...(genres?.data || [])];
      takeAllGenresReplica[findIndex] = {
        ...(takeAllGenresReplica?.[findIndex] || {}),
        name: text,
      };
      dispatch(setGenres([...(takeAllGenresReplica || [])]));
      setText("");
      setGenreId("");
      setLoading(false);
      Swal.fire({
        title: "Updated!",
        icon: "success",
        text: `Genre updated successfully!`,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error?.message || `Something went wrong!`,
      });
    } finally {
      setLoading(false);
    }
  };

  const onDeletePress = (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _handleDeleteGenre(params);
      }
    });
  };

  const _handleDeleteGenre = async ({ id }) => {
    try {
      setLoading(true);
      await FIRESTORE.collection("Genres").doc(id).delete();
      const filteredGenres = genres?.data?.filter?.((o) => o?.id !== id);
      dispatch(setGenres([...(filteredGenres || [])]));
      Swal.fire({
        icon: "info",
        title: "Deleted!",
        text: `Genre deleted successfully!`,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error?.message || `Something went wrong!`,
      });
    } finally {
      setLoading(false);
    }
  };

  const _renderLoading = (color) => {
    return (
      <div
        role="status"
        style={{ color: color }}
        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  };

  return STATUS === "loading" ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex h-full flex-1 container py-4 justify-center items-center">
        {_renderLoading(COLORS?.PINK)}
      </div>
    </div>
  ) : STATUS === "success" && DATA?.length > 0 ? (
    <div className="pb-4 container">
      <div className="flex items-center mb-10">
        <form onSubmit={_validate} className="w-full relative">
          <input
            name="genre"
            type="text"
            value={text}
            disabled={loading}
            onSubmit={_validate}
            placeholder={"Add new genre"}
            onChange={({ target }) => setText(target?.value)}
            className="w-full text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
          />
          {!!genreId && (
            <div
              disabled={loading}
              onClick={() => {
                setGenreId("");
                setText("");
              }}
              className="w-6 h-6 absolute z-10 top-0 -right-2 flex justify-center items-center rounded-full bg-white cursor-pointer"
            >
              <IoClose size={20} color="#000" />
            </div>
          )}
        </form>
        <button
          type="submit"
          disabled={loading}
          onClick={_validate}
          className={`flex items-center justify-center ml-5 w-36 sm:w-44 h-11 rounded-full cursor-pointer bg-theme-pink text-white relative`}
        >
          {genreId ? "Update" : "Add"}
          {(loading || text?.trim?.() === "") && (
            <div className="rounded-3xl top-0 left-0 absolute z-30 bg-white bg-opacity-50 w-full h-full flex items-center justify-center mr-5">
              {loading && (
                <div
                  role="status"
                  style={{ width: "1rem", height: "1rem" }}
                  className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
          )}
        </button>
      </div>
      {loading && (
        <div
          className="w-full h-full absolute z-10 flex justify-center items-center"
          style={{ background: "rgba(255, 255, 255, 0.2)" }}
        >
          {_renderLoading(COLORS?.PINK)}
        </div>
      )}
      <div className="grid grid-cols-3">
        {DATA.map((v, i) => {
          return (
            <GenreBox
              key={i}
              {...(v || {})}
              genreId={genreId}
              loading={loading}
              onEditPress={onEditPress}
              onDeletePress={onDeletePress}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="container h-full py-4 flex flex-1 justify-center items-center">
        <p className="text-2xl text-red-600 text-center">No genres found!</p>
      </div>
    </div>
  );
};

export default Genres;
