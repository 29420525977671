import React, { useState } from "react";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { COLORS, FIRESTORE } from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { setClubs } from "../../../redux/slices/userSlice";

const ClubBox = ({ name, photos, address, city, country, id }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    userReducer: { clubs },
  } = useSelector((state) => state);

  const _handleDeleteConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _handleDeleteClub();
      }
    });
  };

  const _handleDeleteClub = async () => {
    setLoading(true);
    await FIRESTORE.collection("clubs").doc(id).delete();
    const filteredClubs = clubs?.data?.filter?.((o) => o?.id !== id);
    dispatch(setClubs([...(filteredClubs || [])]));
    setLoading(false);
  };

  return (
    <div className="flex justify-between flex-col bg-theme-black rounded-3xl w-full sm:w-96 mr-0 sm:mr-5 mt-4 cursor-pointer relative">
      {loading && (
        <div className="rounded-3xl absolute z-30 bg-white bg-opacity-25 w-full h-full flex items-center justify-center">
          <div
            role="status"
            style={{ width: "1.65rem", height: "1.65rem" }}
            className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="flex justify-between flex-col w-full h-full p-3 sm:p-4">
        <div className="flex justify-between items-start">
          <img
            alt="Logo"
            src={photos[0]}
            className="w-28 h-20 rounded-2xl bg-gray-500"
          />
          <div className="flex space-x-3">
            <Link to={`/club/${id}`}>
              <div className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-white border-solid cursor-pointer">
                <BsPencil size={20} color="#ffffff" />
              </div>
            </Link>
            <div
              onClick={() => !loading && _handleDeleteConfirmation()}
              className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-red-500 border-solid cursor-pointer"
            >
              <RiDeleteBin5Fill size={20} color="rgb(239, 68, 68)" />
            </div>
          </div>
        </div>
        <p className="text-white mb-0 pb-0 mt-3 text-xl font-semibold">
          {name}
        </p>
        <div className="flex mt-2">
          <span className="w-6">
            <MdLocationOn size={20} color="#ffffff" />
          </span>{" "}
          <p className="text-white pl-2">
            {`${address}${city ? ` ,${city}` : ""}${
              country ? ` ,${country}` : ""
            }`}
          </p>
        </div>
      </div>
    </div>
  );
};

const ViewClub = () => {
  const {
    userReducer: { clubs },
  } = useSelector((state) => state);
  const [searchTxt, setSearchTxt] = useState("");

  const DATA =
    searchTxt !== ""
      ? clubs?.data?.filter?.((o) =>
          o?.name?.toLowerCase()?.includes?.(searchTxt?.toLowerCase?.())
        ) || []
      : clubs?.data || [];

  const STATUS = clubs?.status;

  return STATUS === "loading" ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex h-full flex-1 container py-4 justify-center items-center">
        <div
          role="status"
          style={{ color: COLORS?.PINK }}
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : STATUS === "success" && (searchTxt !== "" ? true : DATA?.length > 0) ? (
    <div className="pb-4 container">
      <input
        type="text"
        name="search"
        value={searchTxt}
        placeholder={"Search club here..."}
        onChange={({ target }) => setSearchTxt(target?.value)}
        className="mb-3 w-full sm:w-full text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
      />
      <div className="fixed z-10 bottom-10 right-10">
        <Link to="/club">
          <FaPlus
            size={40}
            color="#000"
            className="rounded-full p-1"
            style={{ background: COLORS?.PINK }}
          />
        </Link>
      </div>
      {searchTxt !== "" && DATA?.length < 1 && (
        <div className="py-4 flex flex-1 justify-center items-center">
          <p className="text-xl text-red-600 text-center">
            No searched club found with "{searchTxt}"
          </p>
        </div>
      )}
      {/*  lg:justify-start */}
      <div className="flex flex-wrap justify-center">
        {DATA.map((v, i) => {
          return <ClubBox key={i} {...(v || {})} />;
        })}
      </div>
    </div>
  ) : (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="container h-full py-4 flex flex-1 justify-center items-center">
        <p className="text-2xl text-red-600 text-center">No club found!</p>
      </div>
    </div>
  );
};

export default ViewClub;
