import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import {
  COLORS,
  FIRESTORE,
  MAP_API_KEY,
  SIZE_OPTIONS,
  EVENT_APPROVAL_OPTIONS,
} from "../../utils/constant";
import _ from "lodash";
import moment from "moment";
import Swal from "sweetalert2";
import firebase from "firebase";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Timetable from "./Timetable";
import { CgSize } from "react-icons/cg";
import {
  MdLocationPin,
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import {
  fetchEventTimetable,
  uploadFileToFirebase,
  makeTimetableDataForEventDatabase,
  makeDatabaseTimetableDataForEventData,
} from "../../utils/functions";
import { FiPlus } from "react-icons/fi";
import DatePicker from "react-datepicker";
import Genre from "../../Images/Genre.png";
import Select from "react-dropdown-select";
import { BsChatDots } from "react-icons/bs";
import suffix from "../../Images/suffix.png";
import { HiUserGroup } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import GenrePopup from "../../Components/GenrePopup";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../../redux/slices/userSlice";
import DivContainer from "../../Components/DivContainer";
import { setTimetable } from "../../redux/slices/appSlice";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MultipleFileSelector from "../../Components/MultipleFileSelector";

const Event = () => {
  const params = useParams();
  const { state: paramState } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationRef = useRef(null);
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [location, setLocation] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [description, setDescription] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [size, setSize] = useState([SIZE_OPTIONS[0]]);
  const [loading, setLoading] = useState(
    !!params?.eventId || !!paramState?.data?.eventId
  );
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [showTimetable, setShowTimetable] = useState(false);
  const [imagesAndVideos, setImagesAndVideos] = useState([]);
  const [startDateError, setStartDateError] = useState(false);
  const [eventStatusError, setEventStatusError] = useState(false);
  const [capacity, setCapacity] = useState(SIZE_OPTIONS[0].capacity);
  const [selectedGenresError, setSelectedGenresError] = useState(false);
  const [eventStatus, setEventStatus] = useState([EVENT_APPROVAL_OPTIONS[0]]);
  const [timetableLoading, setTimetableLoading] = useState(
    !!params?.eventId || !!paramState?.data?.eventId
  );

  const EVENT_ID = params?.eventId;

  const {
    userReducer: { genres, events, userData },
  } = useSelector((state) => state);
  let timetable = useSelector((state) => state?.appReducer?.timetable);

  useEffect(() => {
    console.log(makeTimetableDataForEventDatabase(timetable) || {});
  }, [timetable]);

  useEffect(() => {
    if (EVENT_ID) {
      (async () => {
        const findedEvent = events?.data?.find?.(
          (o) => o?.eventId === EVENT_ID
        );
        if (findedEvent) {
          setEventData({ ...(findedEvent || {}) });
          await _setData(findedEvent);
        }
        setLoading(false);
        setTimetableLoading(false);
      })();
    }
    if (paramState?.data?.eventId) {
      const stateData = paramState?.data;
      setTitle(stateData?.title);
      setDescription(stateData?.description);
      setAddress(stateData?.location?.address);
      setLocation({ ...(stateData?.location || {}) });
      setSelectedGenres([...(stateData?.genres || [])]);
      setLink(stateData?.affiliateLink);
      if (stateData?.status) {
        const FIND_STATUS = EVENT_APPROVAL_OPTIONS?.find?.(
          (o) =>
            o?.value?.toLowerCase?.() === stateData?.status?.toLowerCase?.()
        );
        setEventStatus([FIND_STATUS]);
      }
      if (stateData?.size) {
        const FIND_SIZE = SIZE_OPTIONS?.find?.(
          (o) => o?.value?.toLowerCase?.() === stateData?.size?.toLowerCase?.()
        );
        setSize([FIND_SIZE]);
        setCapacity(stateData?.capacity || FIND_SIZE?.capacity);
      }
      setLoading(false);
      setTimetableLoading(false);
    }
  }, []);

  const _setData = async (findedEvent) => {
    const DATA = findedEvent || eventData;
    if (EVENT_ID && DATA) {
      if (DATA?.media?.length > 0) {
        setImagesAndVideos([...(DATA?.media || [])]);
      }
      if ((!DATA?.media || DATA?.media?.length < 0) && !!DATA?.photo) {
        setImagesAndVideos([
          {
            link: DATA?.photo,
            type: "photo",
            name: "",
          },
        ]);
      }
      setTitle(DATA?.title);
      setDescription(DATA?.description);
      setStartDate(new Date(moment(DATA?.startDate, "x").format()));
      if (
        !!DATA?.endDate &&
        DATA?.endDate !== "Invalid date" &&
        DATA?.endDate !== DATA?.startDate
      ) {
        setEndDate(new Date(moment(DATA?.endDate, "x").format()));
      }
      if (!!DATA?.startTime && DATA?.startTime !== "Invalid date") {
        setStartTime(new Date(moment(DATA?.startTime, "HH:mm").format()));
      }
      if (!!DATA?.endTime && DATA?.endTime !== "Invalid date") {
        setEndTime(new Date(moment(DATA?.endTime, "HH:mm").format()));
      }
      setAddress(DATA?.location?.address);
      setLocation({ ...(DATA?.location || {}) });
      setSelectedGenres([...(DATA?.genres || [])]);
      setLink(DATA?.affiliateLink);
      if (DATA?.status) {
        const FIND_STATUS = EVENT_APPROVAL_OPTIONS?.find?.(
          (o) => o?.value?.toLowerCase?.() === DATA?.status?.toLowerCase?.()
        );
        setEventStatus([FIND_STATUS]);
      }
      if (DATA?.size) {
        const FIND_SIZE = SIZE_OPTIONS?.find?.(
          (o) => o?.value?.toLowerCase?.() === DATA?.size?.toLowerCase?.()
        );
        setSize([FIND_SIZE]);
        setCapacity(DATA?.capacity || FIND_SIZE?.capacity);
      }
      if (DATA?.timetable && !_.isEmpty(DATA?.timetable || {})) {
        try {
          const fetchedTimetable = await fetchEventTimetable(DATA);
          const artistMakedData =
            makeDatabaseTimetableDataForEventData(fetchedTimetable);
          dispatch(setTimetable(artistMakedData));
        } catch (error) {}
      }
      setTimetableLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const _onApply = (paramGenres) => {
    setModalShow(false);
    setSelectedGenres([...(paramGenres || [])]);
  };

  const _deleteGenre = (genreId) => {
    if (genreId) {
      setSelectedGenres((prevState) => [
        ...(prevState?.filter((o) => o !== genreId) || []),
      ]);
    }
  };

  const _onAddressHandler = (data, ...arg) => {
    setAddress(data?.label);
    geocodeByPlaceId(data?.value?.place_id)
      .then((results) => {
        setLocation({
          address: data?.label,
          lat: results?.[0]?.geometry?.location?.lat?.(),
          lng: results?.[0]?.geometry?.location?.lng?.(),
        });
      })
      .catch((error) =>
        console.log(
          "error in fetching coordinates using geocodeByPlaceId ===> ",
          error
        )
      );
  };

  const _clear = async () => {
    setTitleError(false);
    setImageError(false);
    setLocationError(false);
    setStartDateError(false);
    setEventStatusError(false);
    setSelectedGenresError(false);
    dispatch(setTimetable({}));

    if (EVENT_ID) {
      await _setData();
    } else {
      setLink("");
      setTitle("");
      setAddress("");
      setEndTime(null);
      setEndDate(null);
      setStartDate(null);
      setDescription("");
      setStartTime(null);
      setSelectedGenres([]);
      setImagesAndVideos([]);
      setEventStatus([EVENT_APPROVAL_OPTIONS[0]]);
    }
  };

  const _handleStartTime = (time) => {
    // const selectedNow = moment();
    // const selectedTime = moment(time);

    // if (
    //   moment().format("DD/MM/YYYY") ===
    //     moment(startDate).format("DD/MM/YYYY") &&
    //   (selectedTime.isSame(selectedNow) || selectedTime.isBefore(selectedNow))
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Start time must be future time",
    //   });
    //   return;
    // }
    setStartTime(time);
    // setEndTime("");
  };

  const _handleEndTime = (time) => {
    // const selectedNow = moment(time);
    // const selectedStartTime = moment(startTime);
    // if (
    //   selectedNow.isSameOrBefore(selectedStartTime) &&
    //   (endDate ? moment(startDate).isSame(endDate) : true)
    // ) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "Event end time should be after starting time",
    //   });
    //   return;
    // }
    setEndTime(time);
  };

  const _handleUpdate = async () => {
    try {
      setSubmitLoading(true);
      let imagesAndVideoUrls = [];
      const imagesAndVideoFileExists = imagesAndVideos?.filter((o) => o?.file);
      const imagesAndVideolinkExists = imagesAndVideos?.filter((o) => o?.link);
      if (imagesAndVideoFileExists?.length > 0) {
        const downloadUrls = await uploadFileToFirebase(
          imagesAndVideoFileExists,
          "event_images"
        );
        imagesAndVideoUrls = [
          ...(imagesAndVideolinkExists || []),
          ...(downloadUrls || []),
        ];
      } else {
        imagesAndVideoUrls = imagesAndVideolinkExists;
      }
      const TITLE = title?.trim?.();
      const eventObj = {
        location,
        type: "public",
        capacity: capacity || "",
        size: size?.[0]?.value || "",
        genres: selectedGenres,
        userId: eventData?.userId,
        affiliateLink: link || "",
        title: TITLE?.toLowerCase?.(),
        media: imagesAndVideoUrls || [],
        status: eventStatus?.[0]?.value || "",
        description: description?.trim?.() || "",
        ...(!_.isEmpty(timetable) && {
          timetable: {
            ...(makeTimetableDataForEventDatabase(timetable) || {}),
          },
        }),
        startDate: Number(moment(startDate).startOf("day").format("x")),
        endTime: !!endTime ? moment(endTime).format("HH:mm") : "",
        startTime: !!startTime ? moment(startTime).format("HH:mm") : "",
        endDate: endDate
          ? Number(moment(endDate).startOf("day").format("x"))
          : Number(moment(startDate).startOf("day").format("x")),
        timeStamp: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
      };
      await FIRESTORE.collection("events").doc(EVENT_ID).update(eventObj);
      await FIRESTORE.collection("notifications").add({
        userId: eventData?.userId,
        TITLE,
        id: EVENT_ID,
        type: "event",
        // needs to be updated
        ...(!!imagesAndVideoUrls?.[0]?.link && {
          image: imagesAndVideoUrls?.[0]?.link,
        }),
        message:
          eventData?.status !== eventStatus?.[0]?.value
            ? eventStatus?.[0]?.value === "rejected"
              ? "Your event got rejected!"
              : "You event got approved!"
            : "Some changes made by admin in event!",
      });
      const updatedEventObj = {
        eventId: EVENT_ID,
        ...eventObj,
      };

      const findAllEventIndex = events?.data?.findIndex?.(
        (o) => o?.eventId === EVENT_ID
      );

      let takeAllEventsReplica = [...(events?.data || [])];
      takeAllEventsReplica[findAllEventIndex] = {
        ...updatedEventObj,
      };

      dispatch(setEvents([...(takeAllEventsReplica || [])]));
      Swal.fire({
        title: "Done",
        icon: "success",
        text: `${TITLE} event updated successfully!`,
      });
    } catch (error) {
      console.log("error ===> ", error?.message);
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error?.message || `Something went wrong!`,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const _handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const downloadUrl = await uploadFileToFirebase(
        imagesAndVideos,
        "event_images"
      );
      console.log("imagesAndVideos ==> ", imagesAndVideos);
      const TITLE = title?.trim?.();
      const eventObj = {
        location,
        type: "public",
        capacity: capacity,
        size: size?.[0]?.value,
        genres: selectedGenres,
        media: downloadUrl || [],
        userId: userData?.userId,
        affiliateLink: link || "",
        status: eventStatus?.[0]?.value,
        title: TITLE?.toLowerCase?.(),
        startTime:
          !!startTime && startTime !== "Invalid date"
            ? moment(startTime).format("HH:mm")
            : "",
        endTime:
          !!endTime && endTime !== "Invalid date"
            ? moment(endTime).format("HH:mm")
            : "",
        startDate: Number(moment(startDate).startOf("day").format("x")),
        description: description?.trim?.() || "",
        ...(!_.isEmpty(timetable) && {
          timetable: {
            ...(makeTimetableDataForEventDatabase(timetable) || {}),
          },
        }),
        endDate: endDate
          ? Number(moment(endDate).startOf("day").format("x"))
          : Number(moment(startDate).startOf("day").format("x")),
        createdAt: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
        timeStamp: firebase?.firestore?.FieldValue?.serverTimestamp?.(),
      };
      console.log("eventObj ==> ", eventObj);
      const created = await FIRESTORE.collection("events").add(eventObj);

      const createdEventObj = {
        eventId: created?.id,
        ...eventObj,
      };
      dispatch(setEvents([createdEventObj, ...(events?.data || [])]));
      _clear();
      Swal.fire({
        title: "Done",
        icon: "success",
        text: `${TITLE} event added successfully!`,
      });
    } catch (error) {
      console.log("error ===> ", error?.message);
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error?.message || `Something went wrong!`,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const _validate = () => {
    setTitleError(true);
    setImageError(true);
    setStartDateError(true);
    setLocationError(true);
    setEventStatusError(true);
    setSelectedGenresError(true);
    if (
      title?.trim?.() &&
      (!EVENT_ID ? imagesAndVideos?.length > 0 : true) &&
      startDate &&
      location &&
      address?.trim?.() &&
      eventStatus?.length > 0
    ) {
      if (EVENT_ID) {
        _handleUpdate();
      } else {
        _handleSubmit();
      }
    }
  };

  const IS_DISABLED_TIMETABLE = !!!startDate || !!!endDate;

  const _onBackPress = () => {
    if (showTimetable) {
      setShowTimetable(false);
      return;
    }
    navigate(-1);
  };

  return (
    <>
      <div className="flex justify-between items-center pt-4">
        <button onClick={_onBackPress} className="w-12 cursor-pointer">
          <IoIosArrowBack size={35} color="#fff" />
        </button>
        {!loading && (
          <p className="text-center text-white text-2xl">
            {showTimetable
              ? "Timetable"
              : EVENT_ID
              ? "Edit Event"
              : "Create Event"}
          </p>
        )}
        <div className="w-12 h-1"></div>
      </div>
      {loading || timetableLoading ? (
        <div className="h-screen-custom-fit bg-theme-black">
          <div className="container h-full py-4 flex flex-1 flex-col justify-center items-center">
            <div
              role="status"
              style={{ color: COLORS?.PINK }}
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            {timetableLoading && (
              <>
                <p className="text-bsae mt-4 mb-0 text-white text-center">
                  It may take few moments.
                </p>
                <p className="text-bsae mt-2 mb-0 text-white text-center">
                  {!!EVENT_ID
                    ? "Your event details is getting update..."
                    : "Your event is creating..."}
                </p>
              </>
            )}
          </div>
        </div>
      ) : !loading && EVENT_ID && !eventData ? (
        <div className="h-screen-custom-fit bg-theme-black">
          <div className="container py-4 flex flex-1 h-full justify-center items-center">
            <p className="text-2xl text-red-600 text-center">
              Sorry, event couldn't find!
            </p>
          </div>
        </div>
      ) : showTimetable ? (
        <Timetable
          endDate={endDate}
          startDate={startDate}
          onSavePress={() => setShowTimetable(false)}
        />
      ) : (
        <form onSubmit={handleSubmit} className="bg-black">
          <div className="grid grid-cols-12 gap-2 w-full container items-center py-5">
            <DivContainer>
              <MultipleFileSelector
                loading={submitLoading}
                imagesAndVideos={imagesAndVideos}
                setImagesAndVideos={setImagesAndVideos}
              />
              {imageError && imagesAndVideos?.length < 1 && (
                <p className="mt-3 mb-0 text-red-600 text-sm">
                  At least 1 Event image or video must be required
                </p>
              )}
            </DivContainer>

            {/* title Start  */}
            <DivContainer>
              <div className="flex flex-row items-center">
                <label className="items-center text-white relative">
                  Event Title
                  <div className="absolute top-0 -right-3">
                    <span className="text-red-600 text-xl">*</span>
                  </div>
                </label>
              </div>
              <input
                name="title"
                type="text"
                value={title}
                onChange={({ target }) => setTitle(target?.value)}
                className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
              />
              {titleError && title === "" && (
                <p className="mt-3 mb-0 text-red-600 text-sm">
                  Event title must be required
                </p>
              )}
            </DivContainer>

            <DivContainer>
              <div className="flex flex-row items-center">
                <BsChatDots size={25} color="#fff" />
                <label className="items-center text-white relative ml-2">
                  Description
                </label>
              </div>
              <textarea
                type="text"
                name="description"
                value={description}
                onChange={({ target }) => setDescription(target?.value)}
                className="mt-3 resize-none overflow-hidden h-48 text-white bg-theme-black py-3 px-3 rounded-3xl border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
              ></textarea>
            </DivContainer>

            {/* date picker  */}
            <DivContainer>
              <div className="flex flex-1 flex-col md:flex-row justify-between">
                <div className="flex flex-1 flex-col mr-0 md:mr-3 mb-4 md:mb-0">
                  <div className="flex flex-row items-center mb-2">
                    <label className="items-center text-white relative">
                      Start Date
                      <div className="absolute top-0 -right-3">
                        <span className="text-red-600 text-xl">*</span>
                      </div>
                    </label>
                  </div>
                  <DatePicker
                    selected={startDate}
                    // {...(!EVENT_ID && {
                    //   minDate: new Date(),
                    // })}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="select the date"
                    onChange={(date) => {
                      setStartDate(date);
                      setEndDate(null);
                    }}
                  />
                  {startDateError && !startDate && (
                    <p className="mt-3 mb-0 text-red-600 text-sm">
                      Event start date must be required
                    </p>
                  )}
                </div>
                <div className="flex flex-1 flex-col">
                  <div className="flex flex-row items-center mb-2">
                    <label className="items-center text-white relative">
                      End Date
                    </label>
                  </div>
                  <DatePicker
                    selected={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    disabled={!startDate}
                    placeholderText="select the date"
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </div>
            </DivContainer>

            {/* Time picker  */}
            <DivContainer>
              <div className="flex flex-1 flex-col md:flex-row justify-between">
                <div className="flex flex-1 flex-col mr-0 md:mr-3 mb-4 md:mb-0">
                  <div className="flex flex-row items-center mb-2">
                    <label className="items-center text-white relative">
                      Start Time
                    </label>
                  </div>
                  <DatePicker
                    showTimeInput
                    showTimeSelectOnly
                    selected={startTime}
                    dateFormat="hh:mm:a"
                    disabled={!startDate}
                    onChange={_handleStartTime}
                    placeholderText="select the time"
                  />
                </div>
                <div className="flex flex-1 flex-col">
                  <div className="flex flex-row items-center mb-2">
                    <label className="items-center text-white relative">
                      End Time
                    </label>
                  </div>
                  <DatePicker
                    showTimeInput
                    selected={endTime}
                    showTimeSelectOnly
                    disabled={!startTime}
                    dateFormat="hh:mm:a"
                    onChange={_handleEndTime}
                    placeholderText="select the time"
                  />
                </div>
              </div>
            </DivContainer>

            <DivContainer>
              <div className="flex flex-row items-center mb-3">
                <MdLocationPin size={25} color="#fff" />
                <label className="items-center text-white relative ml-1">
                  Location
                  <div className="absolute top-0 -right-3">
                    <span className="text-red-600 text-xl">*</span>
                  </div>
                </label>
              </div>
              {address !== "" && <p className="text-white">{address}</p>}
              {/* geocodeByAddress need to get lan & long by using "geocodeByAddress" function */}
              <GooglePlacesAutocomplete
                ref={locationRef}
                apiKey={MAP_API_KEY}
                selectProps={{
                  value: address,
                  onChange: _onAddressHandler,
                  defaultInputValue: address || "",
                  // ...(EVENT_ID && {
                  // }),
                  // isClearable: true,
                  // defaultValue: address || "",
                  // styles: {
                  //   input: (provided) => ({
                  //     ...provided,
                  //     // color: "white",
                  //     // borderRadius: "1.5rem",
                  //     // backgroundColor: "#151515",
                  //   }),
                  //   option: (provided) => ({
                  //     ...provided,
                  //     // color: "blue",
                  //   }),
                  //   singleValue: (provided) => ({
                  //     ...provided,
                  //   }),
                  // },
                }}
              />
              {locationError && address === "" && (
                <p className="mt-3 mb-0 text-red-600 text-sm">
                  Event location must be required
                </p>
              )}
            </DivContainer>

            {/* Genre  */}
            <DivContainer>
              <div
                onClick={() => setModalShow(true)}
                className="flex w-full bg-theme-black rounded-3xl my-2 cursor-pointer py-3 px-3 items-center justify-between"
              >
                <div className="flex items-center">
                  <img src={Genre} alt="genre" />
                  <label className="ml-3 text-white">Genre</label>
                </div>
                <MdKeyboardArrowRight size={25} color="#fff" />
              </div>

              <GenrePopup
                show={modalShow}
                onApply={_onApply}
                currentGenres={selectedGenres}
                onClose={() => setModalShow(false)}
              />
              {(!selectedGenres || selectedGenres?.length < 1) &&
                selectedGenresError && (
                  <p className="mt-3 mb-0 text-red-600 text-sm">
                    Choose atleast 1 genre for the event
                  </p>
                )}
            </DivContainer>

            {selectedGenres?.length > 0 && (
              <div className="col-start-1 col-end-13 sm:col-start-3 sm:col-end-11 lg:col-start-4 lg:col-end-10 flex flex-wrap w-full">
                {selectedGenres?.map((v, i) => (
                  <div
                    key={i}
                    onClick={() => _deleteGenre(v)}
                    style={{ background: "#373535" }}
                    className="flex items-center mr-3 pl-3 mt-3 py-2 rounded-xl"
                  >
                    <span className="text-white text-sm pr-3">
                      {genres?.data?.find?.((o) => o?.id === v)?.name}
                    </span>
                    <span className="cursor-pointer px-3 text-gray-50">x</span>
                  </div>
                ))}
              </div>
            )}

            <DivContainer>
              <div className="flex flex-row items-center mb-3">
                <label className="items-center text-white relative">
                  Event Status
                  <div className="absolute top-0 -right-3">
                    <span className="text-red-600 text-xl">*</span>
                  </div>
                </label>
              </div>
              <Select
                labelField="name"
                valueField="value"
                searchable={false}
                keepSelectedInList
                values={eventStatus}
                placeholder="Select event status"
                options={EVENT_APPROVAL_OPTIONS}
                onChange={(values) => setEventStatus(values)}
              />
              {eventStatusError && !eventStatus && (
                <p className="mt-3 mb-0 text-red-600 text-sm">
                  Event status must be required
                </p>
              )}
            </DivContainer>

            <DivContainer>
              <div className="flex flex-row items-center mb-3 mt-3">
                <CgSize size={25} color="#fff" />
                <label className="items-center text-white relative ml-1">
                  Event Size
                </label>
              </div>
              <Select
                values={size}
                labelField="name"
                valueField="value"
                searchable={false}
                keepSelectedInList
                options={SIZE_OPTIONS}
                placeholder="Select event size"
                onChange={(values) => {
                  setSize(values);
                  setCapacity(values?.[0]?.capacity);
                }}
              />
            </DivContainer>

            <DivContainer>
              <div className="flex flex-row items-center mt-3">
                <HiUserGroup size={25} color="#fff" />
                <label className="items-center text-white relative ml-1">
                  Event Capacity
                </label>
              </div>
              <input
                type="text"
                name="capacity"
                value={capacity}
                onChange={({ target }) => setCapacity(target?.value)}
                className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl capitalize border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
              />
            </DivContainer>
            <DivContainer>
              <div
                // onClick={() => setShowTimetable(true)}
                onClick={() => !IS_DISABLED_TIMETABLE && setShowTimetable(true)}
                className={`flex w-full bg-theme-black rounded-3xl my-2 ${
                  IS_DISABLED_TIMETABLE ? "" : "cursor-pointer"
                }  py-3 px-3 items-center justify-between`}
              >
                <div className="flex items-center">
                  <img src={Genre} alt="genre" />
                  <label
                    className={`ml-3 ${
                      IS_DISABLED_TIMETABLE ? "text-gray-400" : "text-white"
                    }`}
                  >
                    {_.isEmpty(timetable) ? "Add Timetable" : "View Timetable"}
                  </label>
                </div>
                {_.isEmpty(timetable) ? (
                  <FiPlus
                    size={25}
                    color={IS_DISABLED_TIMETABLE ? "#9ca3af" : "#ffffff"}
                  />
                ) : (
                  <MdOutlineKeyboardArrowRight size={25} color={"#ffffff"} />
                )}
              </div>
            </DivContainer>

            <DivContainer>
              <div className="flex flex-row items-center">
                <img src={suffix} alt="button" className="mr-3" />
                <label className="items-center text-white relative">
                  Link for button
                </label>
              </div>
              <input
                type="text"
                value={link}
                onChange={({ target }) => setLink(target?.value)}
                className="mt-3 text-white bg-theme-black py-3 px-3 rounded-3xl border-transparent border-0 ring-0 focus:border-transparent focus:ring-0"
              />
            </DivContainer>

            {/* last buttons  */}
            <DivContainer>
              <div className="flex justify-evenly items-center">
                <button
                  onClick={_clear}
                  disabled={submitLoading}
                  className="flex items-center justify-between w-36 sm:w-44 pr-5 rounded-full cursor-pointer border"
                >
                  <div className="w-9 h-9 sm:w-11 sm:h-11 rounded-full flex justify-center items-center">
                    <IoIosClose size={30} color="#fff" />
                  </div>
                  <span className="text-white text-center flex flex-1 justify-center items-center text-sm sm:text-base">
                    Clear
                  </span>
                </button>
                <button
                  type="submit"
                  onClick={_validate}
                  disabled={submitLoading}
                  style={{ background: COLORS?.PINK }}
                  className={`flex items-center ${
                    submitLoading ? "justify-center" : "justify-between pl-5"
                  }  w-36 sm:w-44 h-11 rounded-full cursor-pointer`}
                >
                  {submitLoading ? (
                    <div
                      role="status"
                      style={{ width: "1.65rem", height: "1.65rem" }}
                      className="spinner-border animate-spin inline-block border-2 text-center text-white rounded-full"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <span className="text-white text-sm sm:text-base">
                        {EVENT_ID ? "Update Event" : "Create Event"}
                      </span>
                      <div className="w-9 h-9 sm:w-11 sm:h-11 rounded-full bg-white flex justify-center items-center">
                        <AiOutlinePlus size={20} color="#000" />
                      </div>
                    </>
                  )}
                </button>
              </div>
            </DivContainer>
          </div>
        </form>
      )}
    </>
  );
};

export default Event;
