import React from "react";
// import { Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";
// import { BsPencil } from "react-icons/bs";
import { useSelector } from "react-redux";
import { FaPhoneAlt } from "react-icons/fa";
import { COLORS } from "../../utils/constant";
// import { RiDeleteBin5Fill } from "react-icons/ri";

const UserBox = ({ name, phone, email, photo }) => {
  return (
    <div className="flex justify-between flex-col bg-theme-black rounded-3xl w-full sm:w-96 mr-0 sm:mr-5 p-3 sm:p-4 mt-4">
      <div className="flex justify-between items-start">
        <img
          alt="Logo"
          className="w-28 h-20 rounded-2xl bg-gray-500"
          src={photo || require("../../Images/moertsch_white_icon.png")}
        />
        {/* <div className="flex space-x-3">
          <div className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-white border-solid cursor-pointer">
            <BsPencil size={20} color="#ffffff" />
          </div>
          <div className="w-10 h-10 flex justify-center items-center rounded-full border-2 border-red-500 border-solid cursor-pointer">
            <RiDeleteBin5Fill size={20} color="rgb(239, 68, 68)" />
          </div>
        </div> */}
      </div>
      <p className="text-white mb-0 pb-0 mt-3 text-xl font-semibold">
        {name || "Moertsch User"}
      </p>
      {email !== "" && email !== null && email !== undefined && (
        <div className="flex mt-2">
          <span className="w-6">
            <MdEmail size={20} color="#ffffff" />
          </span>{" "}
          <p className="text-white pl-2">{email}</p>
        </div>
      )}
      {phone !== "" && phone !== null && phone !== undefined && (
        <div className="flex mt-2">
          <span className="w-6">
            <FaPhoneAlt size={20} color="#ffffff" />
          </span>{" "}
          <p className="text-white pl-2">{phone}</p>
        </div>
      )}
    </div>
  );
};

const User = () => {
  const {
    userReducer: { users },
  } = useSelector((state) => state);

  const DATA = users?.data || [];
  const STATUS = users?.status;

  return STATUS === "loading" ? (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="flex h-full flex-1 container py-4 justify-center items-center">
        <div
          role="status"
          style={{ color: COLORS?.PINK }}
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  ) : STATUS === "success" && DATA?.length > 0 ? (
    <div className="pb-4 container">
      {/*  lg:justify-start */}
      <div className="flex flex-wrap justify-center">
        {DATA.map((v, i) => {
          return <UserBox key={i} {...(v || {})} />;
        })}
      </div>
    </div>
  ) : (
    <div className="h-screen-custom-fit bg-theme-black w-full">
      <div className="container h-full py-4 flex flex-1 justify-center items-center">
        <p className="text-2xl text-red-600 text-center">No user found!</p>
      </div>
    </div>
  );
};

export default User;
